import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import ReactImageMagnify from 'react-image-magnify';
// Import Swiper styles
import "swiper/css";
import { useEffect, useRef, useState } from "react";
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from "../../Context/CartContext";

const Orderview = () => {
  const buttonRefReturn = useRef(null);

  let navigation = useNavigate();
  const { orderId } = useParams()
  const [getOrderDetails, SetorderDetails] = useState([]);
  const [getGetAddressDetails, SetAddressDetails] = useState([]);
  const [getIsDownloadIng, setIsDownloadIng] = useState(false);
  const { addToCart } = useCart()
  const [selectedImage, setSelectedImage] = useState(
    "/images/LandingPg/banner1.png"
  );

  const [getProduct, setProduct] = useState();
  const [getImage, setImage] = useState('');
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {

        let api = ApiNames.getMyOrderById
        const response = await Axios.get(
          `${api}${orderId}`
        );
        setProduct(response.data.productDetails)
        SetorderDetails(response.data.orderDetails)
        SetAddressDetails(response.data?.orderDetails?.address[0])

      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    checkLoginStatus();
  }, []);


  // Cancel Order

  function cancelOrder() {
    navigation(`/CancelOrder/${orderId}`)
  }



  // review logics////
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [review, setReview] = useState('');
  const [file, setFile] = useState(null);



  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    return inputDate.toLocaleDateString('en-US', options);
  }





  function gotoReturn() {
    if (buttonRefReturn.current) {
      // Trigger a click event on the button
      buttonRefReturn.current.click();
    }
    navigation(`/OrderReturn/${orderId}`)
  }

  function gotoExchange() {
    if (buttonRefReturn.current) {
      // Trigger a click event on the button
      buttonRefReturn.current.click();
    }
    navigation(`/ExchangeOrder/${orderId}`)
  }


  const downloadInvoice = async () => {
    try {
      setIsDownloadIng(true)
      let api = `${ApiNames.invoiceDownload}?orderId=${orderId}`
      const response = await Axios.get(`${api}`, {
        responseType: 'arraybuffer', // Important for binary data
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsDownloadIng(false)
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      setIsDownloadIng(false)
    }
  };



  function fixtheNuber(data) {
    if (data !== undefined && data !== null) {
      const numericData = parseFloat(data); // Convert input string to a number
      if (!isNaN(numericData)) { // Check if the conversion was successful
        const roundedValue = Math.round(numericData); // Round to nearest integer
        return roundedValue; // Format to two decimal places
      }
    }
    return ''; // Or any other default value or behavior you prefer
  }

  return (
    <>
      <div className="container" style={{ marginTop: 60 }}>
        <div className="about-item">
          <div className="row">
            <div className="col-md-4">
              <div className="about-item-image">
                <img
                  src={getProduct?.thumbnail}
                  alt=""
                  className="top-image-item"
                />
              </div>
              <div className="downloadBtns">
                <div className="row">
                  {/* <div className="col-md-6">
                    <button className="add-cart-btn mb-3">  Download Invoice</button>
                  </div> */}
                  {getOrderDetails?.deliveryStatus === 'Order Placed' ? (
                    <div className="col-md-6">
                      <button onClick={cancelOrder} className="add-cart-btn">Cancel Order</button>
                    </div>
                  ) : (
                    null
                  )}
                  {getOrderDetails?.deliveryStatus === 'Order Placed' ? (
                    <div className="col-md-6">
                      <button className="add-cart-btn">Track Order</button>
                    </div>
                  ) : getOrderDetails?.deliveryStatus === 'Cancelled' ? (
                    null
                  ) : getOrderDetails?.deliveryStatus === 'Return Requested' ? (
                    <div className="col-md-6">
                      <button className="add-cart-btn"> Return track order</button>
                    </div>
                  ) : getOrderDetails?.deliveryStatus === 'Return request accepted' ? (
                    <div className="col-md-6">
                      <button className="add-cart-btn"> Return track order</button>
                    </div>
                  ) :
                    getOrderDetails?.deliveryStatus === 'Return Processing' ? (
                      <div className="col-md-6">
                        <button className="add-cart-btn"> Return track order</button>
                      </div>
                    ) : getOrderDetails?.deliveryStatus === 'Exchange Requested' ? (
                      <div className="col-md-6">
                        <button className="add-cart-btn"> Exchange track order</button>
                      </div>
                    ) : getOrderDetails?.deliveryStatus === 'Returned' || getOrderDetails?.deliveryStatus === 'Exchanged' ? (
                      null
                    ) :
                      getOrderDetails?.deliveryStatus === 'Order shipping started' ? (
                        <div className="col-md-6">
                          <button className="add-cart-btn"> Track Order</button>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <button data-bs-toggle="modal" data-bs-target="#exampleModal3" className="add-cart-btn">Return/Exchange</button>
                        </div>
                      )
                  }


                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="about-details">
                <p className="item-para">{getProduct?.title}
                </p>
                <p className="item-para">Delivery Type : <span>{getOrderDetails?.deliveryMethod}</span></p>
                <p className="item-para">Order Id : <span>{getOrderDetails?.receipt}</span></p>

                <p className="inclusiveline quantitys">Quantity : <span>{getOrderDetails?.quantity}</span></p>
                <p className="inclusiveline deleveryStatys">{getOrderDetails?.deliveryStatus}</p>
                <p className="inclusivelines">{formatDate(getOrderDetails.createdDate)}</p>
                <div className="quantity-details">

                  <p className="quantity-para">Delivery Address</p>
                  <p className="quantity-parasR">{getGetAddressDetails?.firstName} {getGetAddressDetails?.lastName}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.address}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.appartment}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.companyName}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.city}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.state}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.pincode}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.emailId}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.type}</p>
                  <p className="quantity-paras">{getGetAddressDetails?.countryCode}- {getGetAddressDetails?.mobileNumber}</p>

                </div>


                <div className="rating-stars">
                  <p className="write-review"> {fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)} AED   <span data-bs-toggle="modal"
                    data-bs-target="#exampleModal" className="write-Breakup">View Fare Breakup</span></p>
                </div>
                {/* <p className="costumer-review">500 Costumer Review</p> */}
              </div>
            </div>
          </div>
        </div>



        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down m-top">
            <div className="modal-content M-content-below ">
              <div className="modal-header M-header">
                <h1 className="modal-title fs-5 payment-title" id="exampleModalLabel">
                  Payment Information
                </h1>
                <button
                  type="button"
                  className="btn-close close-icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body m-body">
                <div className="payment-body">
                  <ul className="payment-info-top">
                    <li className="payment-list">
                      <p className="left-priceText">{getProduct?.title.slice(0, 30)}...</p>
                      <h1 className="amount-right">{getOrderDetails?.quantity} x {getOrderDetails.productPrice}  AED</h1>
                    </li>
                    {/* <li className="payment-list">
                      <p className="left-priceText">Tax Price</p>
                      <h1 className="amount-right">{fixtheNuber(getOrderDetails.totalTaxCharges)} AED</h1>
                    </li> */}
                    <li className="payment-list">
                      <p className="left-priceText">Shipping Fee ({getOrderDetails?.deliveryMethod})</p>
                      <h1 className="amount-right">{fixtheNuber(getOrderDetails.overallShippingCharges)} AED</h1>
                    </li>
                    <li className="payment-list border-btm">
                      <p className="left-priceText">Total Amount</p>
                      <h1 className="amount-right">{fixtheNuber(getOrderDetails.totalCost)} AED</h1>
                    </li>
                    <li className="payment-list border-btm">
                      <p className="left-priceText">Coupon discount</p>
                      <h1 className="amount-right"> -{fixtheNuber(getOrderDetails?.discountedPrice)} AED</h1>
                    </li>
                    <li className="payment-list border-btm">
                      <p className="left-priceText">Grand Total</p>
                      <h1 className="amount-right">{fixtheNuber(getOrderDetails?.totalCost - getOrderDetails?.discountedPrice)} AED</h1>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="modal-footer">
                {getIsDownloadIng === true ? (
                  <button className="invoice-btn">Downloading...</button>

                ) : (
                  <button onClick={downloadInvoice} className="invoice-btn">Download Invoice</button>

                )}
              </div>

            </div>
          </div>
        </div>
        {/* <!-- Modal Return or Exchange --> */}
        <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-sm return-Mtop">
            <div className="modal-content return-Mdata">
              <div className="modal-header return-Mheader">
                <h1 className="modal-title choose-title fs-5" id="exampleModalLabel">Choose any one</h1>
                <button type="button" ref={buttonRefReturn} className="btn-close close-iccon" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body return-Mbody">
                <div className="return-xchange-top">
                  <div onClick={gotoReturn} className="return-inside">  <p className="return-txt"><img className="returnBoxIcn" src="/images/returnIcon.svg" alt="..." />Return Product </p><p className="right-arrow"><i className="fas fa-chevron-right"></i></p></div>
                  <div onClick={gotoExchange} className="return-inside">  <p className="return-txt"><img className="returnBoxIcn" src="/images/exchange.svg" alt="..." />Exchange Product </p><p className="right-arrow"><i className="fas fa-chevron-right"></i></p></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderview;
