import { useNavigate } from 'react-router-dom';

// Custom hook to handle navigation
const useRouteFunction = () => {
    const navigate = useNavigate();

    const routeFunction = (path) => {
        navigate(path);
    };

    return routeFunction;
};

export default useRouteFunction;
