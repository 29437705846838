import React, { useEffect } from 'react'

const ReturnPolicy = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
  return (
    <div>

        
    <div className="return-policy" style={{ paddingTop: 30 }}>
        <div className="container">
      <h1 className="return-policytitle">Returns Policy</h1>
      <p className="return-policytext">Please read all the conditions below.</p>
      <p className="return-policytext">
        Flipdeals reserves the right to refuse a return/refund/exchange request.
      </p>
      <p className="return-policytext">
        Please make your claims for <strong className="return-policystrong">"damaged items on delivery"</strong> or missing items within 12 hours of receipt of your order.
      </p>
      
      <h2 className="return-policysubtitle">Is my product eligible for return?</h2>
      <p className="return-policytext">
        All of the products sold on Flipdeals are eligible for returns, if not stated otherwise on the product page. Some products may not be eligible for returns due to the nature of the product. These may include assembled PCs (as the components are unboxed to be used in the computer assembly).
      </p>
      <p className="return-policytext">
        If you decide to return your purchase, you can do so within 14 days after your purchase. The following conditions apply:
      </p>
      <ul className="return-policylist">
        <li className="return-policylist-item">The product must be returned with the original packaging, sealed, and unopened</li>
        <li className="return-policylist-item">
          If the product has been used, or the product’s original box was opened, or the original seal was removed, we will decline the refund and return the product back to you unless there is a manufacturing defect
        </li>
        <li className="return-policylist-item">
          If the product is not working due to a manufacturing defect, the original box, information booklet, and all other accessories must be returned too
        </li>
      </ul>
      <p className="return-policytext">
        If your item is eligible for a return, you will be able to raise the return request through the Order History page in your Flipdeals user account. Flipdeals returns team shall contact you within 24 hours for claim validation. After confirmation, we will send a courier to collect the item to be returned from you.
      </p>

      <h2 className="return-policysubtitle">Cancelling an order</h2>
      <p className="return-policytext">
        You may cancel your order from your user account page. In case the order cancellation option is not visible, then you may reach out to us either by phone, email, or live chat to request for the order cancellation. If you decide to cancel the order, please do so as soon as possible.
      </p>
      <p className="return-policytext">
        If you could let us know the reason for cancellation, we will improve our service and perhaps do things a little better next time.
      </p>

      <h2 className="return-policysubtitle">Payment refunds</h2>
      <p className="return-policytext">
        Refunds are processed within 2-4 working days after the product is returned, inspected, and is accepted for a return. Refunds will be done only through the original mode of payment. Refunds to a credit/debit card may take up to 10 business days to arrive. Refunds to a bank account usually take 1-5 business days to arrive to your bank account.
      </p>
      <p className="return-policytext">
        If the return was initiated for reasons other than receiving a faulty, damaged or incorrect item, the customer will be responsible for covering the applicable delivery charges, import fees, and duties.
      </p>
      </div>

      
    </div>
    </div>
  )
}

export default ReturnPolicy