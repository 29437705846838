import React, { useEffect, useState } from 'react';
import Axios from '../../utils/AxiosConfi';
import Toaster from "../../utils/Toaster";
import ApiUrls from '../../Constants/ApiUrls';
import ApiNames from '../../Constants/ApiUrls';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    review: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    try {
      let api = ApiNames.contactUs
      const response = await Axios.post(api, formData);
      Toaster(response.data?.message)
      console.log(response.data); // Assuming the API returns some data
      setSuccess(true);
      setFormData({ name: '', email: '', review: '' }); // Clear form after successful submission
    } catch (err) {
      Toaster(err.response.data.error)
      // setError(err.message); // Set error message
    }
    setSubmitting(false);
  };

  return (
    <div className="contact-us-section">
      <div className="contact-top">
        <div className="container">
          <h1 className="contact-us">Contact Us</h1>
          <p className="contactus-text">
            Reach out to us through our Contact Us page for any assistance,
            queries, or feedback you may have. Whether you're seeking help with
            your account, have questions about our services, or want to provide
            input, we're here to listen and support you. Our dedicated team is
            committed to providing prompt and personalized assistance to ensure
            your satisfaction.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="contact-SubSection">
          <div className="row h-100">
            <div className="col-md-8">
              <div className="inquiry-box-top">
                <p className="get-touch">Get in touch!</p>
                <p className="choose-method">Choose your preferred method</p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <input
                      type="text"
                      className="form-control contact-input-text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <input
                      type="email"
                      className="form-control contact-input-text"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <textarea
                      className="form-control text-box"
                      rows="3"
                      placeholder="Describe your review"
                      name="review"
                      value={formData.review}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="Inquiry-top-btn">
                    <button type="submit" className="inquiry-btn" disabled={submitting}>
                      {submitting ? 'Submitting...' : 'Send Inquiry'}
                    </button>
                  </div>
                </form>
                {error && <p className="text-danger">{error}</p>}
                {success && <p className="text-success">Your inquiry has been submitted successfully!</p>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="row flex-column  h-100 ">
                <div className="col mb-2" >
                  <div className="lets-talk-top">
                    <span className="let-talk">Let's Talk</span>
                    <p className="number-inside">
                      <img className="talk-icon" src="/images/call.svg" alt="..." />
                      <a className='emailList'href="tel:+971-4-804-9888">+971-4-804-9888</a>
                       
                    </p>
                    <span className="call-us">Please call us on the above number!</span>
                  </div>
                </div>
                <div className="col">
                  <div  className="lets-talk-top">
                    <span className="let-talk">Let's Mail</span>
                    <p className="number-inside">
                      <img className="talk-icon message-icon" src="/images/email.svg" alt="..." />
                    <a className='emailList' href="mailto:info@flipdeals.com">  info@flipdeals.com</a>
                    </p>
                    <span className="call-us">Please mail us at the above address!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
