import React from 'react'
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import { useEffect, useState } from "react";
import { useCart } from "../../Context/CartContext";
import { NavLink, useParams } from 'react-router-dom';
import Toaster from '../../utils/Toaster';
const AllBlogs = () => {
  const [blogsData, setBlogsData] = useState([]);
  const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart()
  const { id } = useParams();
  const [subscribemail, setsubscribemail] = useState("");
  const [subscribemailErr, setsubscribemailErr] = useState("");
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    const getBlogs = async () => {
      try {
        let api = ApiNames.getBlogs
        const response = await Axios.get(
          `${api}`
        );

        setBlogsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, [selectRefresh]);

  const handleChange = (event) => {
    // console.log("---->", event.target.name, event.target.value);
    if (event.target.name === "subscribemail") {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setsubscribemailErr("");
      } else {
        setsubscribemailErr("please enter correct email");
      }
      setsubscribemail(event.target.value);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscribemail === "" || subscribemailErr !== "") {
      alert("Please fill all input fields");
      return;
    } // api call
    const url = ApiNames.subscribeForm;
    const body = {
      email: subscribemail,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, body, { headers: headerTypes })
      .then((res) => {
        if (res.data.code === "success") {
          setsubscribemail("");
          Toaster(res.data.message);
        } else {
          setsubscribemail("");
          Toaster(res.data.message);
        }
      })
      .catch((error) => {
        Toaster(error.response.data.message);
      });
  };
  return (
    <>

      <div className="allblogs-data">
        <div className="container">
          <div className="allblogs-header">
            <h5 className="allblogs-para">Blog</h5>
            <h1 className="allblogs-head"> Insight and Updates </h1>
            <p className="allblogs-teampara">
              Our blog offers a wealth of insightful content and regular updates,
              keeping you informed about industry trends, expert perspectives, and
              valuable tips to enhance your knowledge and stay ahead in your field.
            </p>
            <div className="allblogs-information">
              <form onSubmit={handleSubmit}>
                <div className="allblogs-search">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="allblogs-search-inside"
                    id="email"
                    value={subscribemail}
                    name="subscribemail"
                    onChange={handleChange}

                  />
                  <button className="allblogs-button">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="desort-board-background">
          <div className="container">
            <div className="desort-board">
              <div className="row">
                {blogsData.map((products, index) => (
                  <div key={index} className=" col-md-6">

                    <NavLink
                      style={{ textDecoration: "none" }}
                      to={`/bloginfo/${products.title?.replace(/\s+/g, '-')}/${products._id}`}
                    >
                      <div className="desort-card">
                        <div className="card desort-card-item">
                          <div className='blog-image-data'>
                            <img
                              src={products?.image}
                              className="desort-img-top"
                              alt="no-data"
                            />
                          </div>
                          <div className="card-body">
                            <p className="desort-whitten-para">{products?.date}</p>
                            <h1 className="desort-compounding-head">
                              {products?.title}
                            </h1>
                       
                            <button className="desort-question-button">Read More</button>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllBlogs