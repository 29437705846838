import CryptoJS from 'crypto-js';
import ApiNames from '../Constants/ApiUrls';


// Function to encrypt sensitive data
const decryptData = (encryptedData) => {
    // Example decryption using CryptoJS
    const bytes = CryptoJS.AES.decrypt(encryptedData, ApiNames.secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
  




export default decryptData    