import React, { useState } from "react";
import Pagination from "react-js-pagination";

const PaginationComponent = ({ totalCount,onFunction }) => {
  const [activePage, setActivePage] = useState(1); // Start from page 1
  const itemsPerPage =20;
  
  const handlePageChange = (pageNumber) => {
    debugger
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
    onFunction(pageNumber)
  
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  return (
    <Pagination
    activePage={activePage}
    itemsCountPerPage={itemsPerPage}
    totalItemsCount={totalCount}
    pageRangeDisplayed={5}
    onChange={handlePageChange}
    prevPageText={<i className="fas fa-angle-left"></i>}
    nextPageText={<i className="fas fa-angle-right"></i>}
    firstPageText={<i className="fas fa-angle-double-left"></i>}
    lastPageText={<i className="fas fa-angle-double-right"></i>}
    hideFirstLastPages={true}
    itemClass="page-item" // Custom class for each page item
    linkClass="page-link" // Custom class for the page links
    activeClass="active"  // Active class for the current page
    disabledClass="disabled" // Disabled class for non-clickable buttons
  />
  );
};

export default PaginationComponent;
