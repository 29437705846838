import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextTitle from "../../Component/Title/TextTitle";
import MegaDealsBnr from "../../Component/Banners/MegaDealsBnr";
import ProductItems from "../../Component/ProductItems/ProductItems";
import { useCart } from "../../Context/CartContext";
import ProductItemsNew from "../../Component/ProductItems/ProductItemsNew";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
const InsideProduct = () => {
  const { id } = useParams();
  // const [getItems, setItems] = useState([]);
  const [Products, setProducts] = useState([]);
  const [bestProducts, setBestProducts] = useState([]);
  const [getPriceDropd, setPriceDroped] = useState([]);
  const [getBannerImage, setBannerImage] = useState([]);
  const [getCurated, setCurated] = useState([]);
  const {addToCart, whenLoginRefreshProduct,cartItems} = useCart()
  useEffect(() => {
    
    const getDiscountedProduct = async () => {
      try {
        let url = ApiNames.getAllMegaDeals
        const response = await Axios.get(
          `${url}`
        );
        setProducts(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDiscountedProduct();
  }, []);
  useEffect(() => {
    const getMoreReason = async () => {
      try {
        let token = await localStorage.getItem('token')
        let url = ApiNames.getCategoryWiseProductsList
        
        if(token){
          const response = await Axios.get(
            `${url}${id}`
          );
          setBestProducts(response.data.products);
          // setPriceDroped(response.data.getPriceDropProducts);
          // setCurated(response.data.CuratedProducts);
          // setBannerImage(response.data.categoryInfo.bannerImage)
          
        }
        else{
          const response = await Axios.get(
            `${url}${id}`
          );
          setBestProducts(response.data.products);
          // setPriceDroped(response.data.getPriceDropProducts);
          // setCurated(response.data.CuratedProducts);
          // setBannerImage(response.data.categoryInfo.bannerImage)
        }
     

    
      } catch (error) {
        console.log(error);
      }
    };
    getMoreReason();
  }, []);
  useEffect(() => {
    const getMoreReason = async () => {
   
      try {
        let url = ApiNames.getCategoryWiseProductsList
        let token = await localStorage.getItem('token')
        if(token){
          const response = await Axios.get(
            `${url}${id}`
          );
          setBestProducts(response.data.products);
          // setPriceDroped(response.data.getPriceDropProducts);
          // setCurated(response.data.CuratedProducts);
          // setBannerImage(response.data.categoryInfo.bannerImage)
        }
        else{
          const response = await Axios.get(
            `${url}${id}`
          );
          setBestProducts(response.data.products);
          // setPriceDroped(response.data.getPriceDropProducts);
          // setCurated(response.data.CuratedProducts);
          // setBannerImage(response.data.categoryInfo.bannerImage)
        }
     
        
  
      } catch (error) {
        console.log(error);
      }
    };
    getMoreReason();
  }, [cartItems]);

  useEffect(() => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}, []);

  return (
    <div className="Product-page">
      {/* <div style={{marginBottom:'100'}} className="inner-Banner-Top">
        <img
          className="InnerBanner-images"
          src={getBannerImage}
          alt="..."
        />
      </div> */}
     <div className="container">
   
     {bestProducts.length > 0 && (
        <div>
          {/* <TextTitle text="Best sellers " className="textSub-title" /> */}
          <ProductItemsNew
           productObj={bestProducts} />
        </div>
      )}

     </div>
    </div>
  );
};

export default InsideProduct;
