import React, { useEffect } from 'react'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <div>
            <div className="privacy-policy"  style={{ paddingTop: 30 }}>
                <div className="container">
                    <h1 className="privacy-policytitle">Privacy Policy</h1>

                    <p className="privacy-policytext">
                        This privacy notice for Flipdeals.com (doing business as Flipdeals)
                        ("Flipdeals," "we," "us," or "our"), describes how and why we might
                        collect, store, use, and/or share ("process") your information when
                        you use our services ("Services"), such as when you:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Visit our website at{" "}
                            <a href="http://www.Flipdeals.com">www.Flipdeals.com</a>, or any
                            website of ours that links to this privacy notice
                        </li>
                        <li className="privacy-policylist-item">
                            Download and use our mobile application (Flipdeals), or any other
                            application of ours that links to this privacy notice
                        </li>
                        <li className="privacy-policylist-item">
                            Engage with us in other related ways, including any sales,
                            marketing, or events
                        </li>
                    </ul>

                    <p className="privacy-policytext">
                        <span className="boldtxt">Questions or concerns? </span> Reading this
                        privacy notice will help you understand your privacy rights and
                        choices. If you do not agree with our policies and practices, please
                        do not use our Services. If you still have any questions or concerns,
                        please contact us at{" "}
                        <a href="mailto:support@flipdeals.com">support@Flipdeals.com</a>.
                    </p>

                    <h2 className="title2">SUMMARY OF KEY POINTS</h2>
                    <p className="text-italic">
                        This summary provides key points from our privacy notice, but you can
                        find out more details about any of these topics by clicking the link
                        following each key point or by using our table of contents below to
                        find the section you are looking for. You can also click here to go
                        directly to our table of contents.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">
                            What personal information do we process?{" "}
                        </span>
                        When you visit, use, or navigate our Services, we may process personal
                        information depending on how you interact with Flipdeals and the
                        Services, the choices you make, and the products and features you use.
                    </p>

                    <p className="privacy-policytext">
                        {" "}
                        <span className="boldtxt">
                            Do we process any sensitive personal information?{" "}
                        </span>
                        We do not process sensitive personal information.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">
                            Do we receive any information from third parties?{" "}
                        </span>
                        We do not receive any information from third parties.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">How do we process your information? </span>
                        We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and
                        to comply with the law. We may also process your information for other
                        purposes with your consent.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">
                            In what situations and with which types of parties do we share
                            personal information?{" "}
                        </span>
                        We may share information in specific situations and with specific
                        categories of third parties.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">
                            How do we keep your information safe?{" "}
                        </span>
                        We have organizational and technical processes and procedures in place
                        to protect your personal information. However, no electronic
                        transmission over the internet or information storage technology can
                        be guaranteed to be 100% secure, so we cannot promise or guarantee
                        that hackers, cybercriminals, or other unauthorized third parties will
                        not be able to defeat our security and improperly collect, access,
                        steal, or modify your information.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">What are your rights? </span>
                        Depending on where you are located geographically, the applicable
                        privacy law may mean you have certain rights regarding your personal
                        information.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">How do you exercise your rights? </span>
                        The easiest way to exercise your rights is by filling out our data
                        subject request form available here:{" "}
                        <a href="mailto:support@Flipdeals.com">support@Flipdeals.com</a>, or
                        by contacting us. We will consider and act upon any request in
                        accordance with applicable data protection laws.
                    </p>

                    <p className="privacy-policytext">
                        <span className="boldtxt">
                            Want to learn more about what Flipdeals does with any information we
                            collect?{" "}
                        </span>
                        Click here to review the notice in full.
                    </p>

                    <h2 className="title2">TABLE OF CONTENTS</h2>
                    <ul className="privacy-policytoc">
                        <li className="privacy-policytoc-item">
                            1. WHAT INFORMATION DO WE COLLECT?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            2. HOW DO WE PROCESS YOUR INFORMATION?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                            INFORMATION?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            9. HOW LONG DO WE KEEP YOUR INFORMATION?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            10. HOW DO WE KEEP YOUR INFORMATION SAFE?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            11. DO WE COLLECT INFORMATION FROM MINORS?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            12. WHAT ARE YOUR PRIVACY RIGHTS?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            13. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </li>
                        <li className="privacy-policytoc-item">
                            14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            15. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            16. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            17. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
                        </li>
                        <li className="privacy-policytoc-item">
                            18. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                            YOU?{" "}
                        </li>
                    </ul>

                    <h2 className="title2">1. WHAT INFORMATION DO WE COLLECT? </h2>

                    <h3 className="privacy-policysubsection">
                        Personal information you disclose to us
                    </h3>
                    <p className="privacy-policytext">
                        In Short: We collect personal information that you provide to us
                    </p>

                    <p className="privacy-policytext">
                        We collect personal information that you voluntarily provide to us
                        when you register on the Services, express an interest in obtaining
                        information about us or our products and Services, when you
                        participate in activities on the Services, or otherwise when you
                        contact us.
                    </p>

                    <h3 className="privacy-policysubsection">
                        Personal Information Provided by You
                    </h3>
                    <p className="privacy-policytext">
                        The personal information that we collect depends on the context of
                        your interactions with us and the Services, the choices you make, and
                        the products and features you use. The personal information we collect
                        may include the following:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">names</li>
                        <li className="privacy-policylist-item">phone numbers</li>
                        <li className="privacy-policylist-item">email addresses</li>
                        <li className="privacy-policylist-item">billing addresses</li>
                        <li className="privacy-policylist-item">contact preferences</li>
                        <li className="privacy-policylist-item">passwords</li>
                        <li className="privacy-policylist-item">usernames</li>
                        <li className="privacy-policylist-item">mailing addresses</li>
                        <li className="privacy-policylist-item">debit/credit card numbers</li>
                        <li className="privacy-policylist-item">
                            contact or authentication data
                        </li>
                        <li className="privacy-policylist-item">images and videos</li>
                    </ul>

                    <h3 className="privacy-policysubsection">Sensitive Information</h3>
                    <p className="privacy-policytext">
                        We do not process sensitive information.
                    </p>

                    <h3 className="privacy-policysubsection">Payment Data</h3>
                    <p className="privacy-policytext">
                        Payment Data. We may collect data necessary to process your payment if
                        you make purchases, such as your payment instrument number, and the
                        security code associated with your payment instrument. All payment
                        data is stored by __________ . You may find their privacy notice
                        link(s) here:
                    </p>

                    <h3 className="privacy-policysubsection">Social Media Login Data</h3>
                    <p className="privacy-policytext">
                        We may provide you with the option to register with us using your
                        existing social media account details, like your Facebook, Twitter, or
                        other social media account. If you choose to register in this way, we
                        will collect the information described in the section called " HOW DO
                        WE HANDLE YOUR SOCIAL LOGINS? " below.
                    </p>

                    <h3 className="privacy-policysubsection">Application Data</h3>
                    <p className="privacy-policytext">
                        If you use our application(s), we also may collect the following
                        information if you choose to provide us with access or permission:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Mobile Device Data. We automatically collect device information
                            (such as your mobile device ID, model, and manufacturer), operating
                            system, version information and system configuration information,
                            device and application identification numbers, browser type and
                            version, hardware model Internet service provider and/or mobile
                            carrier, and Internet Protocol (IP) address (or proxy server). If
                            you are using our application(s), we may also collect information
                            about the phone network associated with your mobile device, your
                            mobile device’s operating system or platform, the type of mobile
                            device you use, your mobile device’s unique device ID, and
                            information about the features of our application(s) you accessed.
                        </li>
                        <li className="privacy-policylist-item">
                            Push Notifications. We may request to send you push notifications
                            regarding your account or certain features of the application(s). If
                            you wish to opt out from receiving these types of communications,
                            you may turn them off in your device's settings.
                        </li>
                    </ul>
                    <p className="privacy-policytext">
                        This information is primarily needed to maintain the security and
                        operation of our application(s), for troubleshooting, and for our
                        internal analytics and reporting purposes.
                    </p>
                    <p className="privacy-policytext">
                        All personal information that you provide to us must be true,
                        complete, and accurate, and you must notify us of any changes to such
                        personal information.
                    </p>

                    <h3 className="privacy-policysubsection">
                        Information automatically collected
                    </h3>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short:</span> Some information — such as
                        your Internet Protocol (IP) address and/or browser and device
                        characteristics — is collected automatically when you visit our
                        Services.
                    </p>
                    <p className="privacy-policytext">
                        We automatically collect certain information when you visit, use, or
                        navigate the Services. This information does not reveal your specific
                        identity (like your name or contact information) but may include
                        device and usage information, such as your IP address, browser and
                        device characteristics, operating system, language preferences,
                        referring URLs, device name, country, location, information about how
                        and when you use our Services, and other technical information. This
                        information is primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and reporting
                        purposes.
                    </p>
                    <p className="privacy-policytext">
                        Like many businesses, we also collect information through cookies and
                        similar technologies.
                    </p>

                    <p className="privacy-policytext">
                        The information we collect includes:
                    </p>
                    <ul>
                        <li className="privacy-policytext">
                            <span className="boldtxt">Log and Usage Data.</span>
                            Log and usage data is service-related, diagnostic, usage, and
                            performance information our servers automatically collect when you
                            access or use our Services and which we record in log files.
                            Depending on how you interact with us, this log data may include
                            your IP address, device information, browser type, and settings and
                            information about your activity in the Services (such as the
                            date/time stamps associated with your usage, pages and files viewed,
                            searches, and other actions you take such as which features you
                            use), device event information (such as system activity, error
                            reports (sometimes called "crash dumps" ), and hardware settings).
                        </li>
                        <li className="privacy-policytext">
                            <span className="boldtxt">Device Data</span>
                            We collect device data such as information about your computer,
                            phone, tablet, or other device you use to access the Services.
                            Depending on the device used, this device data may include
                            information such as your IP address (or proxy server), device and
                            application identification numbers, location, browser type, hardware
                            model, Internet service provider and/or mobile carrier, operating
                            system, and system configuration information.
                        </li>
                        <li className="privacy-policytext">
                            <span className="boldtxt">Location Data</span>. We collect location
                            data such as information about your device's location, which can be
                            either precise or imprecise. How much information we collect depends
                            on the type and settings of the device you use to access the
                            Services. For example, we may use GPS and other technologies to
                            collect geolocation data that tells us your current location (based
                            on your IP address). You can opt out of allowing us to collect this
                            information either by refusing access to the information or by
                            disabling your Location setting on your device. However, if you
                            choose to opt out, you may not be able to use certain aspects of the
                            Services.
                        </li>
                    </ul>

                    <h2 className="title2">2. HOW DO WE PROCESS YOUR INFORMATION? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short:</span> We process your
                        information to provide, improve, and administer our Services,
                        communicate with you, for security and fraud prevention, and to comply
                        with law. We may also process your information for other purposes with
                        your consent.
                    </p>
                    <p className="privacy-policytext ">
                        {" "}
                        <span className="boldtxt">
                            We process your personal information for a variety of reasons,
                            depending on how you interact with our Services, including:
                        </span>
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            {" "}
                            <span className="boldtxt">
                                To facilitate account creation and authentication and otherwise
                                manage user accounts.
                            </span>{" "}
                            We may process your information so you can create and log in to your
                            account, as well as keep your account in working order.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To deliver and facilitate delivery of services to the user.
                            </span>
                            We may process your information to provide you with the requested
                            service.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To respond to user inquiries/offer support to users.
                            </span>{" "}
                            We may process your information to respond to your inquiries and
                            solve any potential issues you might have with the requested
                            service.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To send administrative information to you.
                            </span>
                            . We may process your information to send you details about our
                            products and services, changes to our terms and policies, and other
                            similar information.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">To fulfill and manage your orders.</span>
                            We may process your information to fulfill and manage your orders,
                            payments, returns, and exchanges made through the Services.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">To request feedback.</span>. We may
                            process your information when necessary to request feedback and to
                            contact you about your use of our Services.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To send you marketing and promotional communications.
                            </span>
                            We may process the personal information you send to us for our
                            marketing purposes, if this is in accordance with your marketing
                            preferences. You can opt out of our marketing emails at any time.
                            For more information, see " WHAT ARE YOUR PRIVACY RIGHTS? " below.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To deliver targeted advertising to you.
                            </span>
                            We may process your information to develop and display personalized
                            content and advertising tailored to your interests, location, and
                            more.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">To protect our Services.</span>We may
                            process your information as part of our efforts to keep our Services
                            safe and secure, including fraud monitoring and prevention.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">To identify usage trends.</span>We may
                            process information about how you use our Services to better
                            understand how they are being used so we can improve them.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To determine the effectiveness of our marketing and promotional
                                campaigns.
                            </span>
                            We may process your information to better understand how to provide
                            marketing and promotional campaigns that are most relevant to you.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                To save or protect an individual's vital interest.
                            </span>
                            We may process your information when necessary to save or protect an
                            individual’s vital interest, such as to prevent harm.
                        </li>
                    </ul>

                    <h2 className="title2">
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                        INFORMATION?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>We only process your
                        personal information when we believe it is necessary and we have a
                        valid legal reason (i.e. , legal basis) to do so under applicable law,
                        like with your consent, to comply with laws, to provide you with
                        services to enter into or fulfill our contractual obligations, to
                        protect your rights, or to fulfill our legitimate business interests.
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            {" "}
                            <span className="boldtxt"> Consent.</span> We may process your
                            information if you have given us permission (i.e. , consent) to use
                            your personal information for a specific purpose. You can withdraw
                            your consent at any time. Click here to learn more.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">Performance of a Contract.</span> We may
                            process your personal information when we believe it is necessary to
                            fulfill our contractual obligations to you, including providing our
                            Services or at your request prior to entering into a contract with
                            you.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt"> Legitimate Interests.</span> We may
                            process your information when we believe it is reasonably necessary
                            to achieve our legitimate business interests and those interests do
                            not outweigh your interests and fundamental rights and freedoms. For
                            example, we may process your personal information for some of the
                            purposes described in order to:
                        </li>
                        <li className="list-unstyled">
                            <ul>
                                <li className="privacy-policylist-item">
                                    Send users information about special offers and discounts on our
                                    products and services
                                </li>{" "}
                                <li className="privacy-policylist-item">
                                    Develop and display personalized and relevant advertising
                                    content for our users
                                </li>
                                <li className="privacy-policylist-item">
                                    Analyze how our Services are used so we can improve them to
                                    engage and retain users
                                </li>
                                <li className="privacy-policylist-item">
                                    Support our marketing activities
                                </li>
                                <li className="privacy-policylist-item">
                                    Diagnose problems and/or prevent fraudulent activities
                                </li>
                                <li className="privacy-policylist-item">
                                    Understand how our users use our products and services so we can
                                    improve user experience
                                </li>
                            </ul>
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt"> Legal Obligations.</span> We may process
                            your information where we believe it is necessary for compliance
                            with our legal obligations, such as to cooperate with a law
                            enforcement body or regulatory agency, exercise or defend our legal
                            rights, or disclose your information as evidence in litigation in
                            which we are involved.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt"> Vital Interests.</span> We may process
                            your information where we believe it is necessary to protect your
                            vital interests or the vital interests of a third party, such as
                            situations involving potential threats to the safety of any person.
                        </li>
                    </ul>
                    <p className="text-italic">
                        If you are located in Canada, this section applies to you.
                    </p>
                    <p className="privacy-policytext">
                        We may process your information if you have given us specific
                        permission (i.e. , express consent) to use your personal information
                        for a specific purpose, or in situations where your permission can be
                        inferred (i.e. , implied consent). You can withdraw your consent at
                        any time. Click here to learn more.
                    </p>
                    <p className="privacy-policytext">
                        In some exceptional cases, we may be legally permitted under
                        applicable law to process your information without your consent,
                        including, for example:
                    </p>
                    <ul>
                        <li className="privacy-policylist-item">
                            If collection is clearly in the interests of an individual and
                            consent cannot be obtained in a timely way
                        </li>{" "}
                        <li className="privacy-policylist-item">
                            For investigations and fraud detection and prevention
                        </li>
                        <li className="privacy-policylist-item">
                            For business transactions provided certain conditions are met
                        </li>
                        <li className="privacy-policylist-item">
                            {" "}
                            If it is contained in a witness statement and the collection is
                            necessary to assess, process, or settle an insurance claim
                        </li>
                        <li className="privacy-policylist-item">
                            For identifying injured, ill, or deceased persons and communicating
                            with next of kin
                        </li>
                        <li className="privacy-policylist-item">
                            If we have reasonable grounds to believe an individual has been, is,
                            or may be victim of financial abuse
                        </li>
                        <li className="privacy-policylist-item">
                            {" "}
                            If it is reasonable to expect collection and use with consent would
                            compromise the availability or the accuracy of the information and
                            the collection is reasonable for purposes related to investigating a
                            breach of an agreement or a contravention of the laws of Canada or a
                            province
                        </li>
                        <li className="privacy-policylist-item">
                            If disclosure is required to comply with a subpoena, warrant, court
                            order, or rules of the court relating to the production of records
                        </li>
                        <li className="privacy-policylist-item">
                            If it was produced by an individual in the course of their
                            employment, business, or profession and the collection is consistent
                            with the purposes for which the information was produced
                        </li>
                        <li className="privacy-policylist-item">
                            If the collection is solely for journalistic, artistic, or literary
                            purposes
                        </li>
                        <li className="privacy-policylist-item">
                            If the information is publicly available and is specified by the
                            regulations
                        </li>
                    </ul>

                    <h2 className="title2">
                        4.WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </h2>
                    <p className="privacy-policytext">
                        {" "}
                        <span className="boldtxt"> In Short: </span>
                        We may share information in specific situations described in this
                        section and/or with the following categories of third parties.
                    </p>
                    <p className="privacy-policytext">
                        {" "}
                        <span className="boldtxt">
                            Vendors, Consultants, and Other Third-Party Service Providers.{" "}
                        </span>{" "}
                        We may share your data with third-party vendors, service providers,
                        contractors, or agents ( "third parties" ) who perform services for us
                        or on our behalf and require access to such information to do that
                        work. We have contracts in place with our third parties, which are
                        designed to help safeguard your personal information. This means that
                        they cannot do anything with your personal information unless we have
                        instructed them to do it. They will also not share your personal
                        information with any organization apart from us. They also commit to
                        protect the data they hold on our behalf and to retain it for the
                        period we instruct. The categories of third parties we may share
                        personal information with are as follows:
                    </p>
                    <ul>
                        <li className="privacy-policylist-item">Data Analytics Services</li>
                        <li className="privacy-policylist-item">Payment Processors</li>
                        <li className="privacy-policylist-item">Shipping Companies</li>
                        <li className="privacy-policylist-item">Ad Networks</li>
                        <li className="privacy-policylist-item">Retargeting Platforms</li>
                    </ul>
                    <p className="privacy-policytext">
                        {" "}
                        We also may need to share your personal information in the following
                        situations:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            <span className="boldtxt"> Business Transfers.</span> We may share
                            or transfer your information in connection with, or during
                            negotiations of, any merger, sale of company assets, financing, or
                            acquisition of all or a portion of our business to another company.
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">
                                When we use Google Maps Platform APIs.
                            </span>{" "}
                            We may share your information with certain Google Maps Platform APIs
                            (e.g. , Google Maps API, Places API). To find out more about
                            Google’s Privacy Policy, please refer to this link . We use certain
                            Google Maps Platform APIs to retrieve certain information when you
                            make location-specific requests. This includes: Delivery location ;
                            and other similar information. A full list of what we use
                            information for can be found in this section and in the previous
                            section titled " HOW DO WE PROCESS YOUR INFORMATION? " . We obtain
                            and store on your device ( "cache" ) your location . You may revoke
                            your consent anytime by contacting us at the contact details
                            provided at the end of this document. The Google Maps Platform APIs
                            that we use store and access cookies and other information on your
                            devices. If you are a user currently in the European Economic Area
                            (EU countries, Iceland, Liechtenstein, and Norway) or the United
                            Kingdom, please take a look at our Cookie Notice .
                        </li>
                        <li className="privacy-policylist-item">
                            <span className="boldtxt">Affiliates.</span> We may share your
                            information with our affiliates, in which case we will require those
                            affiliates to honor this privacy notice. Affiliates include our
                            parent company and any subsidiaries, joint venture partners, or
                            other companies that we control or that are under common control
                            with us.
                        </li>
                    </ul>

                    <h2 className="title2">
                        5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>We are not responsible for
                        the safety of any information that you share with third parties that
                        we may link to or who advertise on our Services, but are not
                        affiliated with, our Services.
                    </p>
                    <p className="privacy-policytext">
                        The Services may link to third-party websites, online services, or
                        mobile applications and/or contain advertisements from third parties
                        that are not affiliated with us and which may link to other websites,
                        services, or applications. Accordingly, we do not make any guarantee
                        regarding any such third parties, and we will not be liable for any
                        loss or damage caused by the use of such third-party websites,
                        services, or applications. The inclusion of a link towards a
                        third-party website, service, or application does not imply an
                        endorsement by us. We cannot guarantee the safety and privacy of data
                        you provide to any third parties. Any data collected by third parties
                        is not covered by this privacy notice. We are not responsible for the
                        content or privacy and security practices and policies of any third
                        parties, including other websites, services, or applications that may
                        be linked to or from the Services. You should review the policies of
                        such third parties and contact them directly to respond to your
                        questions.
                    </p>

                    <h2 className="title2">
                        6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>We may use cookies and
                        other tracking technologies to collect and store your information.
                    </p>
                    <p className="privacy-policytext">
                        We may use cookies and similar tracking technologies (like web beacons
                        and pixels) to access or store information. Specific information about
                        how we use such technologies and how you can refuse certain cookies is
                        set out in our Cookie Notice .
                    </p>

                    <h2 className="title2">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>If you choose to register
                        or log in to our Services using a social media account, we may have
                        access to certain information about you.
                    </p>
                    <p className="privacy-policytext">
                        Our Services offer you the ability to register and log in using your
                        third-party social media account details (like your Facebook or
                        Twitter logins). Where you choose to do this, we will receive certain
                        profile information about you from your social media provider. The
                        profile information we receive may vary depending on the social media
                        provider concerned, but will often include your name, email address,
                        friends list, and profile picture, as well as other information you
                        choose to make public on such a social media platform.
                    </p>
                    <p className="privacy-policytext">
                        We will use the information we receive only for the purposes that are
                        described in this privacy notice or that are otherwise made clear to
                        you on the relevant Services. Please note that we do not control, and
                        are not responsible for, other uses of your personal information by
                        your third-party social media provider. We recommend that you review
                        their privacy notice to understand how they collect, use, and share
                        your personal information, and how you can set your privacy
                        preferences on their sites and apps.
                    </p>

                    <h2 className="title2">
                        8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span> We may transfer, store,
                        and process your information in countries other than your own.
                    </p>
                    <p className="privacy-policytext">
                        Our servers are located in the United States . If you are accessing
                        our Services from outside the United States , please be aware that
                        your information may be transferred to, stored, and processed by us in
                        our facilities and by those third parties with whom we may share your
                        personal information (see " WHEN AND WITH WHOM DO WE SHARE YOUR
                        PERSONAL INFORMATION? " above), in the United Arab Emirates, United
                        States, and other countries.
                    </p>
                    <p className="privacy-policytext">
                        If you are a resident in the European Economic Area (EEA) or United
                        Kingdom (UK), then these countries may not necessarily have data
                        protection laws or other similar laws as comprehensive as those in
                        your country. However, we will take all necessary measures to protect
                        your personal information in accordance with this privacy notice and
                        applicable law.
                    </p>
                    <h2 className="title2">9. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>We keep your information
                        for as long as necessary to fulfill the purposes outlined in this
                        privacy notice unless otherwise required by law.
                    </p>
                    <p className="privacy-policytext">
                        We will only keep your personal information for as long as it is
                        necessary for the purposes set out in this privacy notice, unless a
                        longer retention period is required or permitted by law (such as tax,
                        accounting, or other legal requirements). No purpose in this notice
                        will require us keeping your personal information for longer than the
                        period of time in which users have an account with us .
                    </p>
                    <p className="privacy-policytext">
                        When we have no ongoing legitimate business need to process your
                        personal information, we will either delete or anonymize such
                        information, or, if this is not possible (for example, because your
                        personal information has been stored in backup archives), then we will
                        securely store your personal information and isolate it from any
                        further processing until deletion is possible.
                    </p>

                    <h2 className="title2">10. HOW DO WE KEEP YOUR INFORMATION SAFE? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>We aim to protect your
                        personal information through a system of organizational and technical
                        security measures.
                    </p>
                    <p className="privacy-policytext">
                        We have implemented appropriate and reasonable technical and
                        organizational security measures designed to protect the security of
                        any personal information we process. However, despite our safeguards
                        and efforts to secure your information, no electronic transmission
                        over the Internet or information storage technology can be guaranteed
                        to be 100% secure, so we cannot promise or guarantee that hackers,
                        cybercriminals, or other unauthorized third parties will not be able
                        to defeat our security and improperly collect, access, steal, or
                        modify your information. Although we will do our best to protect your
                        personal information, transmission of personal information to and from
                        our Services is at your own risk. You should only access the Services
                        within a secure environment.
                    </p>

                    <h2 className="title2">11. DO WE COLLECT INFORMATION FROM MINORS? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span> We do not knowingly
                        collect data from or market to children under 18 years of age
                    </p>
                    <p className="privacy-policytext">
                        We do not knowingly solicit data from or market to children under 18
                        years of age. By using the Services, you represent that you are at
                        least 18 or that you are the parent or guardian of such a minor and
                        consent to such minor dependent’s use of the Services. If we learn
                        that personal information from users less than 18 years of age has
                        been collected, we will deactivate the account and take reasonable
                        measures to promptly delete such data from our records. If you become
                        aware of any data we may have collected from children under age 18,
                        please contact us at __________ .
                    </p>

                    <h2 className="title2">12. WHAT ARE YOUR PRIVACY RIGHTS? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span> In some regions, such as
                        the European Economic Area (EEA), United Kingdom (UK), and Canada ,
                        you have rights that allow you greater access to and control over your
                        personal information. You may review, change, or terminate your
                        account at any time.
                    </p>
                    <p className="privacy-policytext">
                        In some regions (like the EEA, UK, and Canada ), you have certain
                        rights under applicable data protection laws. These may include the
                        right (i) to request access and obtain a copy of your personal
                        information, (ii) to request rectification or erasure; (iii) to
                        restrict the processing of your personal information; and (iv) if
                        applicable, to data portability. In certain circumstances, you may
                        also have the right to object to the processing of your personal
                        information. You can make such a request by contacting us by using the
                        contact details provided in the section " HOW CAN YOU CONTACT US ABOUT
                        THIS NOTICE? " below.
                    </p>
                    <p className="privacy-policytext">
                        We will consider and act upon any request in accordance with
                        applicable data protection laws. If you are located in the EEA or UK
                        and you believe we are unlawfully processing your personal
                        information, you also have the right to complain to your local data
                        protection supervisory authority. You can find their contact details
                        here:
                        https://ec.europa.eu/justice/dataprotection/bodies/authorities/index_en.htm.
                    </p>
                    <p className="privacy-policytext">
                        If you are located in Switzerland, the contact details for the data
                        protection authorities are available here:
                        https://www.edoeb.admin.ch/edoeb/en/home.html.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2"> Withdrawing your consent: </span> If we
                        are relying on your consent to process your personal information,
                        which may be express and/or implied consent depending on the
                        applicable law, you have the right to withdraw your consent at any
                        time. You can withdraw your consent at any time by contacting us by
                        using the contact details provided in the section " HOW CAN YOU
                        CONTACT US ABOUT THIS NOTICE? " below or updating your preferences .
                    </p>
                    <p className="privacy-policytext">
                        However, please note that this will not affect the lawfulness of the
                        processing before its withdrawal nor, when applicable law allows, will
                        it affect the processing of your personal information conducted in
                        reliance on lawful processing grounds other than consent.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">
                            Opting out of marketing and promotional communications:
                        </span>
                        You can unsubscribe from our marketing and promotional communications
                        at any time by clicking on the unsubscribe link in the emails that we
                        send, or by contacting us using the details provided in the section "
                        HOW CAN YOU CONTACT US ABOUT THIS NOTICE? " below. You will then be
                        removed from the marketing lists. However, we may still communicate
                        with you — for example, to send you service-related messages that are
                        necessary for the administration and use of your account, to respond
                        to service requests, or for other non-marketing purposes.
                    </p>
                    <p className="privacy-policysubsection">Account Information</p>
                    <p className="privacy-policytext">
                        If you would at any time like to review or change the information in
                        your account or terminate your account, you can:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Contact us using the contact information provided.
                        </li>
                        <li className="privacy-policylist-item">
                            Log in to your account settings and update your user account.
                        </li>
                    </ul>
                    <p className="privacy-policytext">
                        Upon your request to terminate your account, we will deactivate or
                        delete your account and information from our active databases.
                        However, we may retain some information in our files to prevent fraud,
                        troubleshoot problems, assist with any investigations, enforce our
                        legal terms and/or comply with applicable legal requirements.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2"> Cookies and similar technologies: </span>{" "}
                        If we Most Web browsers are set to accept cookies by default. If you
                        prefer, you can usually choose to set your browser to remove cookies
                        and to reject cookies. If you choose to remove cookies or reject
                        cookies, this could affect certain features or services of our
                        Services. To opt out of interest-based advertising by advertisers on
                        our Services visit http://www.aboutads.info/choices/.
                    </p>
                    <p className="privacy-policytext">
                        If you have questions or comments about your privacy rights, you may
                        email us at support@Flipdeals.com .
                    </p>

                    <h2 className="title2">13. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p className="privacy-policytext">
                        Most web browsers and some mobile operating systems and mobile
                        applications include a Do-NotTrack ( "DNT" ) feature or setting you
                        can activate to signal your privacy preference not to have data about
                        your online browsing activities monitored and collected. At this stage
                        no uniform technology standard for recognizing and implementing DNT
                        signals has been finalized . As such, we do not currently respond to
                        DNT browser signals or any other mechanism that automatically
                        communicates your choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the future, we will
                        inform you about that practice in a revised version of this privacy
                        notice.
                    </p>

                    <h2 className="title2">
                        14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>Yes, if you are a resident
                        of California, you are granted specific rights regarding access to
                        your personal information.
                    </p>
                    <div className="privacy-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="2">Category</th>
                                    <th colSpan="3">Examples</th>
                                    <th>Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2">A. Identifiers</td>
                                    <td colSpan="3">
                                        Contact details, such as real name, alias, postal address,
                                        telephone or mobile contact number, unique personal
                                        identifier, online identifier, Internet Protocol address,
                                        email address, and account name
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        B. Personal information categories listed in the California
                                        Customer Records statute
                                    </td>
                                    <td colSpan="3">
                                        Name, contact information, education, employment, employment
                                        history, and financial information
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        C. Protected classification characteristics under California
                                        or federal law
                                    </td>
                                    <td colSpan="3">Gender and date of birth </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">D. Commercial information </td>
                                    <td colSpan="3">
                                        Transaction information, purchase history, financial details,
                                        and payment information
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">E. Biometric information</td>
                                    <td colSpan="3">Fingerprints and voiceprints</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        F. Internet or other similar network activity
                                    </td>
                                    <td colSpan="3">
                                        Browsing history, search history, online behavior , interest
                                        data, and interactions with our and other websites,
                                        applications, systems, and advertisements
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">G. Geolocation data</td>
                                    <td colSpan="3">Device location</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        H. Audio, electronic, visual, thermal, olfactory, or similar
                                        information
                                    </td>
                                    <td colSpan="3">
                                        Images and audio, video or call recordings created in
                                        connection with our business activities
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        I. Professional or employmentrelated information
                                    </td>
                                    <td colSpan="3">
                                        Business contact details in order to provide you our Services
                                        at a business level or job title, work history, and
                                        professional qualifications if you apply for a job with us
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">J. Education Information</td>
                                    <td colSpan="3">Student records and directory information</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        K. Inferences drawn from other personal information
                                    </td>
                                    <td colSpan="3">
                                        Inferences drawn from any of the collected personal
                                        information listed above to create a profile or summary about,
                                        for example, an individual’s preferences and characteristics
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">L. Sensitive Personal Information</td>
                                    <td colSpan="3"></td>
                                    <td>NO</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="privacy-policytext">
                        California Civil Code Section 1798.83, also known as the "Shine The
                        Light" law, permits our users who are California residents to request
                        and obtain from us, once a year and free of charge, information about
                        categories of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and addresses of
                        all third parties with which we shared personal information in the
                        immediately preceding calendar year. If you are a California resident
                        and would like to make such a request, please submit your request in
                        writing to us using the contact information provided below.
                    </p>
                    <p className="privacy-policytext">
                        If you are under 18 years of age, reside in California, and have a
                        registered account with Services, you have the right to request
                        removal of unwanted data that you publicly post on the Services. To
                        request removal of such data, please contact us using the contact
                        information provided below and include the email address associated
                        with your account and a statement that you reside in California. We
                        will make sure the data is not publicly displayed on the Services, but
                        please be aware that the data may not be completely or comprehensively
                        removed from all our systems (e.g. , backups, etc.).
                    </p>
                    <p className="privacy-policysubsection">CCPA Privacy Notice</p>
                    <p className="privacy-policytext">
                        The California Code of Regulations defines a "resident" as:
                    </p>
                    <p className="privacy-policytext">
                        (1) every individual who is in the State of California for other than
                        a temporary or transitory purpose and
                    </p>
                    <p className="privacy-policytext">
                        (2) every individual who is domiciled in the State of California who
                        is outside the State of California for a temporary or transitory
                        purpose
                    </p>
                    <p className="privacy-policytext">
                        All other individuals are defined as "non-residents."
                    </p>
                    <p className="privacy-policytext">
                        If this definition of "resident" applies to you, we must adhere to
                        certain rights and obligations regarding your personal information.
                    </p>

                    <p className="privacy-policysubsection">
                        What categories of personal information do we collect?
                    </p>
                    <p className="privacy-policytext">
                        We have collected the following categories of personal information in
                        the past twelve (12) months:
                    </p>
                    <p className="privacy-policytext">
                        We will use and retain the collected personal information as needed to
                        provide the Services or for:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Category A - As long as the user has an account with us
                        </li>
                        <li className="privacy-policylist-item">
                            Category B - As long as the user has an account with us
                        </li>
                        <li className="privacy-policylist-item">
                            Category D - As long as the user has an account with us
                        </li>
                        <li className="privacy-policylist-item">
                            Category G - As long as the user has an account with us
                        </li>

                        <li className="privacy-policylist-item">
                            Category K - As long as the user has an account with us
                        </li>
                    </ul>

                    <p className="privacy-policytext">
                        We may also collect other personal information outside of these
                        categories through instances where you interact with us in person,
                        online, or by phone or mail in the context of:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Receiving help through our customer support channels;
                        </li>
                        <li className="privacy-policylist-item">
                            Participation in customer surveys or contests; and
                        </li>
                        <li className="privacy-policylist-item">
                            Facilitation in the delivery of our Services and to respond to your
                            inquiries
                        </li>
                    </ul>
                    <p className="privacy-policysubsection">
                        How do we use and share your personal information?
                    </p>
                    <p className="privacy-policytext">
                        More information about our data collection and sharing practices can
                        be found in this privacy notice .
                    </p>
                    <p className="privacy-policytext">
                        If you are using an authorized agent to exercise your right to opt out
                        we may deny a request if the authorized agent does not submit proof
                        that they have been validly authorized to act on your behalf.
                    </p>
                    <p className="privacy-policysubsection">
                        Will your information be shared with anyone else?
                    </p>
                    <p className="privacy-policytext">
                        We may disclose your personal information with our service providers
                        pursuant to a written contract between us and each service provider.
                        Each service provider is a for-profit entity that processes the
                        information on our behalf, following the same strict privacy
                        protection obligations mandated by the CCPA.
                    </p>
                    <p className="privacy-policytext">
                        We may use your personal information for our own business purposes,
                        such as for undertaking internal research for technological
                        development and demonstration. This is not considered to be "selling"
                        of your personal information.
                    </p>
                    <p className="privacy-policytext">
                        Flipdeals.com has not sold or shared any personal information to third
                        parties for a business or commercial purpose in the preceding twelve
                        (12) months. Flipdeals.com has disclosed the following categories of
                        personal information to third parties for a business or commercial
                        purpose in the preceding twelve (12) months:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Category A. Identifiers, such as contact details like your real
                            name, alias, postal address, telephone or mobile contact number,
                            unique personal identifier, online identifier, Internet Protocol
                            address, email address, and account name.
                        </li>
                        <li className="privacy-policylist-item">
                            Category B. Personal Information, as defined in the California
                            Customer Records law, such as your name, contact information,
                            education, employment, employment history, and financial
                            information.
                        </li>
                    </ul>
                    <p className="privacy-policytext">
                        The categories of third parties to whom we disclosed personal
                        information for a business or commercial purpose can be found under "
                        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" .
                    </p>
                    <p className="privacy-policysubsection">
                        Your rights with respect to your personal data
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">
                            Right to request deletion of the data — Request to delete
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        You can ask for the deletion of your personal information. If you ask
                        us to delete your personal information, we will respect your request
                        and delete your personal information, subject to certain exceptions
                        provided by law, such as (but not limited to) the exercise by another
                        consumer of his or her right to free speech, our compliance
                        requirements resulting from a legal obligation, or any processing that
                        may be required to protect against illegal activities.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">
                            Right to be informed — Request to know
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        Depending on the circumstances, you have a right to know:
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            whether we collect and use your personal information;
                        </li>
                        <li className="privacy-policylist-item">
                            the categories of personal information that we collect;
                        </li>
                        <li className="privacy-policylist-item">
                            the purposes for which the collected personal information is used;
                        </li>
                        <li className="privacy-policylist-item">
                            whether we sell or share personal information to third parties;
                        </li>
                        <li className="privacy-policylist-item">
                            the categories of personal information that we sold, shared, or
                            disclosed for a business purpose;
                        </li>
                        <li className="privacy-policylist-item">
                            the categories of third parties to whom the personal information was
                            sold, shared, or disclosed for a business purpose;
                        </li>
                        <li className="privacy-policylist-item">
                            the business or commercial purpose for collecting, selling, or
                            sharing personal information; and
                        </li>
                        <li className="privacy-policylist-item">
                            the specific pieces of personal information we collected about you.
                        </li>
                    </ul>
                    <p className="privacy-policytext">
                        In accordance with applicable law, we are not obligated to provide or
                        delete consumer information that is de-identified in response to a
                        consumer request or to re-identify individual data to verify a
                        consumer request.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">
                            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
                            Rights
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        We will not discriminate against you if you exercise your privacy
                        rights.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">
                            Right to Limit Use and Disclosure of Sensitive Personal Information
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        We do not process consumer's sensitive personal information.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">Verification process</span>
                    </p>
                    <p className="privacy-policytext">
                        Upon receiving your request, we will need to verify your identity to
                        determine you are the same person about whom we have the information
                        in our system. These verification efforts require us to ask you to
                        provide information so that we can match it with information you have
                        previously provided us. For instance, depending on the type of request
                        you submit, we may ask you to provide certain information so that we
                        can match the information you provide with the information we already
                        have on file, or we may contact you through a communication method
                        (e.g. , phone or email) that you have previously provided to us. We
                        may also use other verification methods as the circumstances dictate
                    </p>
                    <p className="privacy-policytext">
                        We will only use personal information provided in your request to
                        verify your identity or authority to make the request. To the extent
                        possible, we will avoid requesting additional information from you for
                        the purposes of verification. However, if we cannot verify your
                        identity from the information already maintained by us, we may request
                        that you provide additional information for the purposes of verifying
                        your identity and for security or fraud-prevention purposes. We will
                        delete such additionally provided information as soon as we finish
                        verifying you.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2"> Other privacy rights</span>
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            You may object to the processing of your personal information.
                        </li>
                        <li className="privacy-policylist-item">
                            You may request correction of your personal data if it is incorrect
                            or no longer relevant, or ask to restrict the processing of the
                            information.
                        </li>
                        <li className="privacy-policylist-item">
                            You can designate an authorized agent to make a request under the
                            CCPA on your behalf. We may deny a request from an authorized agent
                            that does not submit proof that they have been validly authorized to
                            act on your behalf in accordance with the CCPA.
                        </li>
                        <li className="privacy-policylist-item">
                            You may request to opt out from future selling or sharing of your
                            personal information to third parties. Upon receiving an opt-out
                            request, we will act upon the request as soon as feasibly possible,
                            but no later than fifteen (15) days from the date of the request
                            submission.
                        </li>
                    </ul>
                    <p className="privacy-policytext">
                        To exercise these rights, you can contact us by email at
                        support@Flipdeals.com , or by referring to the contact details at the
                        bottom of this document. If you have a complaint about how we handle
                        your data, we would like to hear from you.
                    </p>

                    <h2 className="title2">
                        15. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short: </span>Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to
                        and use of your personal information.
                    </p>
                    <p className="privacy-policysubsection">Virginia CDPA Privacy Notice</p>
                    <p className="privacy-policytext">
                        Under the Virginia Consumer Data Protection Act (CDPA):
                        "Consumer" means a natural person who is a resident of the Commonwealth acting only in an
                        individual or household context. It does not include a natural person acting in a commercial or
                        employment context. <span className="d-block">"Personal data" means any information that is linked or reasonably linkable to an identified or
                            identifiable natural person. "Personal data" does not include de-identified data or publicly available
                            information.</span>
                        <span className="d-block">"Sale of personal data" means the exchange of personal data for monetary consideration.
                            If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding
                            your personal data.</span>

                    </p>
                    <p className="privacy-policytext">
                        The information we collect, use, and disclose about you will vary depending on how you interact with
                        Flipdeals and our Services. To find out more, please visit the following links:

                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Personal data we collect
                        </li>
                        <li className="privacy-policylist-item">
                            How we use your personal data
                        </li>
                        <li className="privacy-policylist-item">
                            When and with whom we share your personal data
                        </li>

                    </ul>
                    <p className="privacy-policytext">
                        <span className="boldtxt2"> Your rights with respect to your personal data </span>
                    </p>
                    <ul className="privacy-policylist">
                        <li className="privacy-policylist-item">
                            Right to be informed whether or not we are processing your personal data
                        </li>
                        <li className="privacy-policylist-item">
                            Right to access your personal data
                        </li>
                        <li className="privacy-policylist-item">
                            Right to correct inaccuracies in your personal data
                        </li>
                        <li className="privacy-policylist-item">
                            Right to request deletion of your personal data
                        </li>
                        <li className="privacy-policylist-item">
                            Right to obtain a copy of the personal data you previously shared with us
                        </li>
                        <li className="privacy-policylist-item">
                            Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ( "profiling" )
                        </li>


                    </ul>
                    <p className="privacy-policytext">
                        Microless General Trading LLC has not sold any personal data to third parties for business or commercial purposes. Microless General Trading LLC will not sell personal data in the future belonging to website visitors, users, and other consumers
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">Exercise your rights provided under the Virginia CDPA
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        More information about our data collection and sharing practices can be found in this privacy notice .
                    </p>
                    <p className="privacy-policytext">
                        You may contact us by email at support@microless.com , by visiting support@microless.com , or by referring to the contact details at the bottom of this document.

                    </p>
                    <p className="privacy-policytext">
                        If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">Verification process
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.

                    </p>
                    <p className="privacy-policytext">
                        Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.

                    </p>
                    <p className="privacy-policytext">
                        <span className="boldtxt2">Right to appeal
                        </span>
                    </p>
                    <p className="privacy-policytext">
                        If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at __________ . Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the Attorney General to submit a complaint.
                    </p>


                    <h2 className="title2">16. DO WE MAKE UPDATES TO THIS NOTICE? </h2>
                    <p className="privacy-policytext">
                        <span className="boldtxt"> In Short:</span>   Yes, we will update this notice as necessary to stay compliant with
                        relevant laws.
                    </p>
                    <p className="privacy-policytext">
                        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    </p>

                    <h2 className="title2">
                        17. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
                    </h2>
                    <p className="privacy-policytext">
                        If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO)  by email at  __________ ,  or by post to:

                    </p>
                    <p className="privacy-policytext">
                        Microless General Trading LLC
                        <br />
                        Al Quoz 3, First Al Khail Street
                        <br />
                        Dubai , Dubai
                        <br />
                        United Arab Emirates
                    </p>

                    <h2 className="title2">
                        18. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                        YOU?{" "}
                    </h2>
                    <p className="privacy-policytext">

                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: support@microless.com .

                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy