// axiosConfig.js

import axios from "axios";
import decryptData from "./descrypt";

// Create an Axios instance with default configuration
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASEURL, // Set your base URL
    headers: {
        'Content-Type': 'application/json',
        // You can add other common headers here
    },
});

// You can also add interceptors if needed
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    async (response) => {
        // Do something with successful response
        if (response.data.ique === 1) {
            let result = await decryptData(response.data.data)
            let makeObj = {
                data:JSON.parse(result)
            }
            return makeObj ;
        } else {
            return response;
        }

    },
    (error) => {
        // Do something with response error
        document.dispatchEvent(new Event('customServerIssueEvent'));

        return Promise.reject(error);
    }
);

export default instance;
