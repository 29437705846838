const ApiNames = {
    secretKey:`#B3tterPa$$c0d3!`,
    getCategoryWiseProducts: "admin/products/getCategoryWiseProducts",
    getBannerImages: "v1/users/getBannerImages",
    getAllCategory: "v1/users/getAllCategory",
    login_Url :'api/auth/login',
    loginWithGoogle :'api/auth/loginWithGoogle',
    invoiceDownload :'v1/users/invoiceDownload',
    loginWithFacebook :'api/auth/loginWithFacebook',
    login_Verify :'api/auth/verifyOtp',
    user_WishList :'v1/users/wishlist',
    user_getWishListCount :'v1/users/getWishListCount',
    getWishlistIds :'v1/users/getWishlistIds',
    getVariants :'v1/users/getVariants?id=',
    getCategoryGroup :'v1/users/getCategoryGroup?title=',
    matchVariant :'v1/users/matchVariant',
    getProductById :'v1/users/getProductById?id=',
    productByName :'v1/users/getProductByName?title=',
    searchProducts :'v1/users/searchProducts?q=',
    search_product :'v1/users/search-product?q=',
    uploadImage :'admin/products/uploadImg',
    getTitles :'admin/products/getTitles',
    reviewToProduct :'v1/users/reviewToProduct',
    getWishList :'v1/users/getWishList',
    deleteWishList :'v1/users/deleteWishList?id=',
    getSubcategoriesById :'v1/users/getSubcategoriesById?id=',
    addToCart :'v1/users/addToCart',
    getShowCategory :'v1/users/getShowCategory',
    getCategoryByIds :'v1/users/getCategoryByIds',
    Inssearch :'v1/users/Inssearch',    
    profile :'v1/users/profile',
    createAddress :'v1/users/createAddress',
    updateAddress :'v1/users/updateAddress',
    deleteAddress :'v1/users/deleteAddress/',
    filterProducts :'v1/users/filterProducts',
    filtersList :'v1/users/filtersList',
    getSelectedCollection :'v1/users/getSelectedCollection',
    getSelectedCollectionTwo :'v1/users/getSelectedCollectionTwo',
    getShowProducts :'v1/users/getShowProducts',
    getAddress :'v1/users/getAddress',
    getOrderByUser :'v1/users/getOrderByUser',
    // paymentIntiation :'v1/payment/paymentIntiation',
    paymentInitiationWithNetwork :'v1/payment/paymentInitiationWithNetwork',
    paymentVerificationWithNetwork :'v1/payment/paymentVerificationWithNetwork',
    paymentVerification :'v1/payment/paymentVerification',
    getOrderByCash :'v1/users/getOrderByCash',
    getMaxLimit :'v1/users/getMaxLimit',
    Success :'v1/users/Success',
    getAllMegaDeals :'v1/users/getAllMegaDeals',
    searchItems :'v1/users/searchItems?q=',
    updateProfile :'v1/users/updateProfile',
    BestProducts :'v1/users/BestProducts',
    Product_Newarrivels:"v1/users/getNewArraivals",
    getMyOrders :'v1/users/getMyOrders',
    getMyOrderById :'v1/users/getMyOrderById/',
    getDeliveryTypes :'v1/users/getDeliveryTypes?id=',
    getAllCountries :'api/auth/getAllCountries',
    getPramotionalImagesByProductId :'api/auth/getMyOrderById?id=',
    reviewEligible :'v1/users/reviewEligible?productId=',
    returnTheProduct :'v1/users/returnTheProduct',
    cancelOrder :'v1/users/cancelOrder',
    Complemenrty_card:"v1/users/getReasons",
    getrecommendedProducts :'v1/users/recomendedProducts?id=',  
    getCategoryWiseProductsList :'v1/users/getCategoryWiseProductsList?id=',  
    getCategoryWiseSub_All :'v1/users/getCategoryWiseSub_All?id=',  
    getBlogs :'v1/users/allBlogs',  
    getBlogData :'v1/users/blogById?id=',  
    getFaqBySection :'v1/blogs/getFaqBySection?section=',  
    getSelectedBanners :'v1/users/getSelectedBanners',  
    getSimilarProducts :'v1/users/getSimilarProducts',  
    subscribeForm :'v1/users/subscribeNow',  
    applyCoupon :'v1/users/applyCoupon',  
    ShowCollectionsThree :'v1/users/ShowCollectionsThree',  
    contactUs :'v1/users/contactUs',  
    getBrandByPCategoryId :'v1/users/getBrandByPCategoryId',  
    getCollectionByCategory :'v1/users/getCollectionByCategory',  
}
    

export default ApiNames;

