import React, { useState } from 'react';

const StarRating = () => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleStarClick = (value) => {
    if (rating === value) {
      // If clicked on the same rating again, reset to 0
      setRating(0);
    } else {
      setRating(value);
    }
    console.log('Selected rating:', value);
    localStorage.setItem('stars',value)
  };

  const handleStarHover = (value) => {
    setHoveredRating(value);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      const starValue = i;
      let starClass = 'star';

      if (hoveredRating >= i) {
        starClass += ' hovered';
      } else if (rating >= i) {
        starClass += ' filled';
      } else if (rating + 0.5 === i) {
        starClass += ' half-filled';
      }

      stars.push(
        <span
          key={i}
          className={starClass}
          onClick={() => handleStarClick(starValue)}
          onMouseEnter={() => handleStarHover(starValue)}
          onMouseLeave={() => setHoveredRating(0)}
        >
          ★ {/* Unicode for star */}
        </span>
      );
    }

    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
