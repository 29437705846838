import React, { useEffect } from 'react'

const ShippingPolicy = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <div className='container' style={{ paddingTop: 30 }}>
                <div className='shipping-policy-container'>
                    <div className="container">
                        <h1 className=" policy-title">Shipping Policy</h1>
                        <p className="policy-intro">
                            This policy applies to domestic United Arab Emirates orders. This policy is designed
                            to ensure that you are aware of our shipping policies and procedures. By ordering
                            from this store you accept the policies contained herein.
                        </p>

                        <section className="policy-section">
                            <h2 className="policy-heading">A. SHIPPING COSTS</h2>
                            <p className="policy-text">All shipping costs are included in the item price. A flat delivery fee AED 20 per order
                                is charged on the cart.</p>
                        </section>

                        <section className="policy-section">
                            <h2 className="policy-heading">B. TRANSIT, HANDLING, AND DELIVERY</h2>
                            <p className="policy-text">Order processing time is 1-2 working days (Monday to Friday).
                                Shipments are generally in transit for 2-8 working days (Monday to Friday).
                                The order deadline will be 4PM Gulf Standard Time (Dubai).
                            </p>

                        </section>

                        <section className="policy-section">
                            <h2 className="policy-heading">C. TRANSPORT COURIER</h2>
                            <p className="policy-text">We deliver the Products to customers through renowned carriers DHL, FedEx, UPS,
                                FlipXp.</p>
                        </section>

                        <section className="policy-section">
                            <h2 className="policy-heading">D. CHANGE OF ADDRESS</h2>
                            <p className="policy-text">
                                We cannot change the delivery address once it is in transit. If you need to change
                                the place for the delivery of the order, please contact us within 24 hours of placing
                                the order at support@Flipdeals.com

                            </p>
                        </section>

                        <section className="policy-section">
                            <h2 className="policy-heading">E. CANCELLATION</h2>
                            <p className="policy-text">If you change your mind before you receive your order, we can accept cancellations
                                at any time before your order is shipped. If an order has already shipped, please see
                                our refund policy.</p>
                        </section>

                        <section className="policy-section">
                            <h2 className="policy-heading">F. PARCELS DAMAGED IN TRANSIT</h2>
                            <p className="policy-text">If you find that a package is damaged in transit, if possible, please reject the
                                package from the courier and contact our customer service. If the package was
                                delivered without you being present, please contact customer service with the
                                following steps.</p>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShippingPolicy