import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Axios from "../../utils/AxiosConfi";
import ApiNames from "../../Constants/ApiUrls";
import { Link, useNavigate } from "react-router-dom";
import MySkeleton from "../../Component/Loaders/MySkeleton";

const ProductSlider = () => {
  const navigate = useNavigate();
  const [getBanner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const url = ApiNames.getBannerImages;
        const response = await Axios.get(url);
        const sectionData = response.data.filter(
          (value) => value.sectionId === 1
        );
        setBanner(sectionData);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };
    getAllCategory();
  }, []);

  const gotoSection = (data) => {
    if (data.bannerType === "Product") {
      navigate(`/ProductView/product/${data.categoryId}`);
    } else {
      navigate(`/Filter/${data?.categoryName.replace(/\s+/g, "-")}/1/${data.categoryId}`);
    }
  };

  // Check if loop mode can be enabled
  const loopMode = getBanner.length > 1;

  return (
    <div className="top-Banner">
      {loading ? (
        <MySkeleton /> // Show skeleton loader while loading
      ) : (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={loopMode}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {getBanner.map((banner) => (
            <SwiperSlide key={banner._id}>
              <div className="banner-imageTop" onClick={() => gotoSection(banner)}>
                <img
                  className="banner-images"
                  src={banner.image}
                  alt="Banner"
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default ProductSlider;
