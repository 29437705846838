import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigate, useNavigate, Link } from "react-router-dom";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination, Autoplay } from 'swiper/modules';
import MySkeleton from "../../Component/Loaders/MySkeleton";

const CategoryCard = () => {
  const [getCategory, setCategory] = useState([]);
  const [getId, setId] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getShowCategory
        const response = await Axios.get(
          `${url}`
        );
        setCategory(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);


  return (
    <div>
      {/* <ul className="list-unstyled category-itemTop">


        {getCategory.slice(0, 8).map((product) => (
          <li className="categorycard-list" key={product._id}>
            <div onClick={() => handleItemClick(product?.firstDetail?._id)} className="cards-top">

              <div className="Cimg-top">
                <div className="banner-text">
                  <p className="text-edit">{product?.firstDetail?.categoryName || <MySkeleton />}</p>
                </div>
                <img
                  src={product?.firstDetail?.thumbnail || <MySkeleton />}
                  alt=""
                  className="banner-insideImge"
                />
              </div>
            </div>
          </li>
        ))}

      </ul> */}


      <div className="categoryForMobiles">
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          breakpoints={{

            768: {
              slidesPerView: 4, // For tablets
            },
            1024: {
              slidesPerView: 6, // For small laptops
            },
            1280: {
              slidesPerView: 7, // For larger laptops and desktops
            },
          }}
          navigation={false}
          modules={[Autoplay, Pagination]}
          className="mySwiperNew"
        >

          {getCategory.map((product, index) => (
            <SwiperSlide key={index}>

              <Link
                to={`/Filter/${product?.firstDetail?.categoryName?.replace(/\s+/g, '-')}/1/${product?.firstDetail?._id}`}
                style={{ textDecoration: 'none', color: '#000000' }}
              >
                <div>
                <img style={{ widows: 200, height: '100%', width: '100%', borderRadius: 6 }}
                  src={product?.firstDetail?.thumbnail}
                  alt=""
                  className="banner-insideImge"
                />
                <p className="text-edit" style={{ fontSize: '12px',textAlign:'center',fontWeight:'bold' }}>{product?.firstDetail?.categoryName}</p>

                </div>
              </Link>
            </SwiperSlide>

          ))}
        </Swiper>

      </div>





    </div>

  );
};

export default CategoryCard;
