// import './App.css';

// import Header from './Component/Header/Header';

// function App() {
//   return (
//     <div className="App">
//       <Header/>
//     </div>
//   );
// }

// export default App;
import "./App.css";

import { Route, Routes } from "react-router-dom";

import Layout from "./Pages/Layout/Layout";
import Landing from "./Pages/Landing/Landing";
import InsideProduct from "./Pages/ProductInnerPage/InsideProduct";
import ProductView from "./Pages/ProductView/ProductView";
import Filter from "./Pages/FilterPge/Filter";
import WishItems from "./Pages/WishItems/WishItems";
import CartItems from "./Pages/CartItems/CartItems";
import Checkout from "./Pages/Checkout/Checkout";
import Profile from "./Component/Profile/Profile";
import Orders from "./Component/Orders/Orders";
import Orderview from "./Component/OrderView/OrderView";
import OrderReturn from "./Pages/OrderReturn/OrderReturn";
import ExchangeOrder from "./Pages/Exchange_Order/ExchangeOrder";
import AllBlogs from "./Pages/AllBlogs/AllBlogs";
import BlogData from "./Pages/BlogData/BlogData";
import CancelOrder from "./Pages/cancelOrder/cancelOrder";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Faq from "./Pages/Faq/Faq";
import PayVerification from "./Pages/Checkout/PayVerification";
import TermsAndConditions from "./Component/Footer/TermsAndConditions";
import ShippingPolicy from "./Component/Footer/ShippingPolicy";
import Warrenty from "./Component/Footer/Warrenty";
import ReturnPolicy from "./Component/Footer/ReturnPolicy";
import PrivacyPolicy from "./Component/Footer/PrivacyPolicy";
function App() {
  return (
    <>
   <div>
   <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/InsideProduct/:id" exact element={<InsideProduct />} />
          <Route path="/ProductView/:title/:productId" element={<ProductView/>} />
          <Route path="/Filter/:category/:type/:id" element={<Filter/>} />
          <Route path="/WishItems" element={<WishItems/>} />
          <Route path="/CartItems" element={<CartItems/>} />
          <Route path="/Checkout" element={<Checkout/>} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/Orders" element={<Orders/>} />
          <Route path="/Orderview/:orderId" element={<Orderview/>} />
          <Route path="/OrderReturn/:orderId" element={<OrderReturn/>} />
          <Route path="/CancelOrder/:orderId" element={<CancelOrder/>} />
          <Route path="/ExchangeOrder/:orderId" element={<ExchangeOrder/>} />
          <Route path="/allblogs" element={<AllBlogs/>} />
          <Route path="/ContactUs" element={<ContactUs/>} />
          <Route path="/bloginfo/:title/:id" element={<BlogData/>} />
          <Route path="/Faq" element={<Faq/>} />
          <Route path="/term-of-use" element={<TermsAndConditions/>} />
          <Route path="/Shipping-policy" element={<ShippingPolicy/>} />
          <Route path="/Warrenty-policy" element={<Warrenty/>} />
          <Route path="/Return-policy" element={<ReturnPolicy/>} />
          <Route path="/Privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/Pay_Verification" element={<PayVerification/>} />
        </Route>
      </Routes>
   </div>
    </>
  );
}

export default App;
