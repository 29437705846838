import React from "react";
import { Link, NavLink } from "react-router-dom";
// import './../../../public/images/Term_Of_Use.pdf'
const Footer = () => {

  const openPdf = () => {
    // URL of the PDF
    const pdfUrl = './../../../images/Term_Of_Use.pdf';

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  };

  const openUrl = (type)=>{
    if(type ==='FB'){
      window.open('https://www.facebook.com/share/1BFcD5BH1P/?mibextid=wwXIfr', '_blank');
    }
    if(type ==='INSTA'){
      window.open('https://www.instagram.com/flipdeals.officiall?igsh=MXZra2sxZGdpcW56dg==', '_blank');
    }
    if(type ==='TICKTOK'){
      window.open('https://www.tiktok.com/@flipdealsofficial?_t=ZS-8ulBLOf0BIl&_r=1', '_blank');
    }
  }

  return (
    <div className="global-aims-footer">
      <div className="container">

        <div className="row">
          <div className="col-6 col-md-3">
            <div className="globalaim">
              <img className="logo-edit1" src="/images/logoNew.svg" alt="no-data" />
              <p className="globalaimpara">
                Revolutionizing the online B2B marketplace, offering over 3,500 top-notch products and serving around 10,000 registered users worldwide.
              </p>
              <NavLink to={"/ContactUs"} className="btn publicThemeButton">Call Us Now</NavLink>
              {/* <div className="supportemail">
        <img src="/images/LandingPg/line.svg" className="iconContact" />
          <p href="mailto:support@gmglobalenterprise.com" className="gm-email">
          info@flipdeals.com
          </p>
        </div>
        <div className="supportemail">
        <img src="/images/LandingPg/cl.svg" className="iconContact" />
          <div className="gm-email">
            <p href="tel:+971528943890" className="gm-number">
            +971-4-804-9888
            </p>
          
          </div>
        </div> */}
              {/* <div className="supportemail">
          <i className="fas fa-landmark supportemailicon" />
          <p className="gm-email">
            Office No. 04-038, 4th Floor, Fahidi Heights Office Tower(Al Musalla
            Tower), Bur Dubai, Dubai-UAE, Dubai United Arab Emirates
          </p>
        </div> */}
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="categories">
              <h2 className="categoriesheader">Company</h2>
              <div className="categoriesitems newLinks">
                <NavLink className="categoriesmaterials " to={"/allblogs"}>Blog </NavLink>
              </div>

              <div className="categoriesitems">
                <NavLink className="categoriesmaterials " to={"/Faq"}>FAQ </NavLink>

              </div>
              <div className="categoriesitems">
                <a className="categoriesmaterials">Help Desk</a>
              </div>
              <div className="categoriesitems newLinks">
                <NavLink className="categoriesmaterials " to={"/ContactUs"}>Contact Us </NavLink>
              </div>
              {/* <div className="categoriesitems">
          <a className="categoriesmaterials">Full Store Directory</a>
        </div> */}
              <div className="categoriesitems">
                <NavLink data-bs-toggle="modal"
                  data-bs-target="#exampleModalCommingSoon" className="categoriesmaterials">Become a Seller</NavLink>
              </div>
            </div>
          </div>
          {/* <div className="col-6 col-md-2">
      <div className="categories">
        <h2 className="categoriesheader">Account</h2>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Log in</a>
        </div>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Register</a>
        </div>
       
      </div>
    </div> */}
          <div className="col-6 col-md-3">
            <div className="categories">
              <h2 className="categoriesheader">Our Policies</h2>
              <div className="categoriesitems">
                <NavLink to={"/Shipping-policy"} className="categoriesmaterials ">Shipping Policy</NavLink>
              </div>
              <div className="categoriesitems newLinks">
                <NavLink to={"/Return-policy"} className="categoriesmaterials">Return Policy</NavLink>
              </div>
              <div className="categoriesitems newLinks">
                <NavLink to={"/term-of-use"} className="categoriesmaterials ">Terms of Use</NavLink>
              </div>
              <div className="categoriesitems">
                <NavLink to={"/Warrenty-policy"} className="categoriesmaterials ">Warranty Policy</NavLink>
              </div>


              <div className="categoriesitems">
                <NavLink to={"/Privacy-policy"} className="categoriesmaterials">Privacy Policy</NavLink>
              </div>
              {/* <div className="categoriesitems">
          <a className="categoriesmaterials">Delivery</a>
        </div> */}
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="categories">
              {/* <h2 className="categoriesheader">NEWSLETTER</h2> */}
              <div className="categoriesitems">
                <a className="SecuredPay">Secured Payment Gateways
                </a>
                <div className="visa">
                  <img src="/images/LandingPg/master.png" className="visa-image" />
                  <img src="/images/LandingPg/visaimg.png" className="visa-image" />
                  <img src="/images/LandingPg/american.png" className="visa-image" />
                  <img src="/images/LandingPg/pay.svg" className="visa-image" />
                </div>
              </div>
              <div className="categoriesicons">
                {/* <a>
                  <img src="/images/LandingPg/linkdin.svg" className=" iconTwitter" />
                </a> */}
                <Link onClick={()=>openUrl('FB')}>
                  <img src="/images/LandingPg/fb.svg" className=" iconTwitter" />
                </Link>
                <Link onClick={()=>openUrl('INSTA')}>
                  <img src="/images/LandingPg/insta.svg" className=" iconTwitter" />
                </Link>

                {/* <a>
                  <img src="/images/LandingPg/twitter.svg" className=" iconTwitter" />
                </a> */}

                <Link  onClick={()=>openUrl('TICKTOK')}>
                  <img src="/images/LandingPg/tick.svg" className=" iconTwitter" />

                </Link>
              </div>
              {/* <div className="categoriesimage">
          <div className="categorieimg">
            <a>
              <i className="fas fa-pen categoriespenicon" />
            </a>
          </div>
        </div> */}

            </div>
          </div>
        </div>
        <div className="footeraim">
          <p className="footerglobal">© 2024 <span className="BrandName">Flip Deals</span>. All rights reserved.</p>
        </div>
      </div>


      <div
        className="modal fade"
        id="exampleModalCommingSoon"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title-become-title" id="exampleModalLabel">
                Become a Seller
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p style={{ textAlign: 'center', margin: 0 }} className="comingSoon-become">Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;
