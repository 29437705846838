import React, { useEffect } from 'react'

const TermsAndConditions = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <div style={{ paddingTop: 20 }}>
            <section className="terms-of-use">
                <div className="container">
                    <h2 className="terms-title">Terms of Use</h2>
                    <p className="terms-last-updated">Last updated: 01/10/2024</p>

                    <p className="terms-paragraph">
                        Welcome to{" "}
                        <a href="http://www.Flipdeals.com" className="terms-link">
                            www.Flipdeals.com
                        </a>{" "}
                        (“Site”). These Terms of Use and all policies and additional terms (if
                        applicable) posted on the Site set out the terms on which we offer you
                        access to and use of our Site, services and applications including our
                        mobile application (collectively, the “Services”). You can find all of
                        our policies and additional terms here:{" "}
                        <a href="http://www.Flipdeals.com" className="terms-link">
                            www.Flipdeals.com
                        </a>{" "}
                        (“Legal Documents”). These Legal Documents are incorporated by
                        reference into these Terms of Use.
                    </p>

                    <p className="terms-paragraph">
                        By accessing, registering and/or continuing to use or access our
                        Services, you are agreeing to be bound by these Terms of Use and the
                        Legal Documents with immediate effect. These Terms of Use and the
                        Legal Documents are subject to change by us at any time. Your
                        continued use of the Site following any such change constitutes your
                        agreement to these Terms of Use and Legal Documents as so modified.
                    </p>

                    <p className="terms-paragraph">
                        References in these Terms of Use to “you” (or similar) are references
                        to you as an individual or legal entity as the case may be.
                    </p>

                    <h3 className="terms-section-title">ABOUT OUR SITE</h3>
                    <p className="terms-paragraph">
                        The Site is an e-commerce platform that allows enterprise users to
                        offer and sell products, and individuals and enterprise entities to
                        buy products.
                    </p>
                    <p className="terms-paragraph">
                        We reserve the right to introduce new Services and update or withdraw
                        any of the Services, in our sole discretion, and we will not be liable
                        to you for exercising this discretion.
                    </p>

                    <h3 className="terms-section-title">
                        1. ELIGIBILITY AND REGISTRATION REQUIREMENTS
                    </h3>
                    <p className="terms-paragraph">
                        1. You are eligible to register as a buyer or seller and benefit from
                        the Services if you meet the following eligibility criteria:
                    </p>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. for buyers:
                            <ul className="terms-sublist">
                                <li className="terms-sublist-item">
                                    1. you are above the legal age for purchasing products in your
                                    country of residence; and
                                </li>
                                <li className="terms-sublist-item">
                                    2. you are able to provide an address in the UAE or the Kingdom
                                    of Saudi Arabia (“KSA”) for delivery of products.
                                </li>
                            </ul>
                        </li>
                        <li className="terms-list-item">
                            2. for sellers:
                            <ul className="terms-sublist">
                                <li className="terms-sublist-item">
                                    1. you are a legal entity duly registered in your jurisdiction;
                                </li>
                                <li className="terms-sublist-item">
                                    2. you have a current trade licence;
                                </li>
                                <li className="terms-sublist-item">
                                    3. you provide proof of authorisation for the individual who
                                    will be registering and using the Site;
                                </li>
                                <li className="terms-sublist-item">
                                    4. you provide identification for the authorised person;
                                </li>
                                <li className="terms-sublist-item">
                                    5. you can provide supporting bank details; and
                                </li>
                                <li className="terms-sublist-item">
                                    6. you acknowledge and agree that for certain product
                                    categories, additional requirements might be applicable.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h3 className="terms-section-title">2. YOUR OBLIGATIONS</h3>
                    <p className="terms-paragraph">
                        1. When using or accessing the Services, you agree that you:
                    </p>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. are responsible for maintaining the confidentiality of, and
                            restricting access to and use of your account and password, and
                            accept responsibility for all activities that occur under your
                            account and password;
                        </li>
                        <li className="terms-list-item">
                            2. agree to immediately notify us of any unauthorised use of your
                            password or account or any other breach of security;
                        </li>
                        <li className="terms-list-item">
                            3. will provide true, accurate, current and complete information
                            about yourself and your use of the Services as required by us;
                        </li>
                        <li className="terms-list-item">
                            4. will not disclose to any third party (except as required or
                            requested by us) a user’s information provided to you; and
                        </li>
                        <li className="terms-list-item">
                            5. will cooperate with our requests for additional information with
                            respect to your eligibility and usage of our Services.
                        </li>
                    </ul>

                    <p className="terms-paragraph">
                        2. When using or accessing the Services, you agree that you will not:
                    </p>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. post, list or upload in any manner any information which is
                            blasphemous, defamatory, obscene, libellous, invasive of another's
                            privacy, hateful, or racially, ethnically objectionable,
                            disparaging, relating or encouraging money laundering or gambling,
                            or otherwise unlawful in any manner whatsoever;
                        </li>
                        <li className="terms-list-item">
                            2. post, list or upload content or items in inappropriate or
                            prohibited categories or areas on our Site, including:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. content or items that may be considered culturally or
                                religiously offensive in any way;
                            </li>
                            <li className="terms-sublist-item">
                                2. content or items which may not be considered to be in
                                compliance with general local law, Islamic law, rules, morals,
                                values, ethics and traditions;
                            </li>
                            <li className="terms-sublist-item">
                                3. content or items that may threaten national security;
                            </li>
                            <li className="terms-sublist-item">
                                4. content or items which may constitute or be considered to
                                promote gambling;
                            </li>
                            <li className="terms-sublist-item">
                                5. securities, including shares, bonds, debentures, or any other
                                financial instruments or assets of any description;
                            </li>
                            <li className="terms-sublist-item">
                                6. living or dead creatures and/or the whole or any part of any
                                animal which has been kept or preserved by any means whether
                                artificial or natural;
                            </li>
                            <li className="terms-sublist-item">
                                7. weapons of any description;
                            </li>
                            <li className="terms-sublist-item">
                                8. liquor, tobacco products, drugs, psychotropic substances,
                                narcotics, intoxicants of any description and medicines;
                            </li>
                            <li className="terms-sublist-item">
                                9. items that to your knowledge are defective, fake, damaged,
                                false or misleading or that may through normal use harm another
                                Site user’s interest or health;
                            </li>
                            <li className="terms-sublist-item">
                                10. non-transferable vouchers; and
                            </li>
                            <li className="terms-sublist-item">11. chemicals.</li>
                        </ul>
                        <li className="terms-list-item">
                            3. post items you do not have a right to link to or include;
                        </li>
                        <li className="terms-list-item">
                            4. post counterfeit or stolen items;
                        </li>
                        <li className="terms-list-item">
                            5. breach or circumvent any laws, third party rights or our systems,
                            policies or determinations of your account status;
                        </li>
                        <li className="terms-list-item">
                            6. use our Services if you no longer fulfil the eligibility criteria
                            or are not able to form legally binding contracts, or are
                            temporarily or indefinitely suspended from using our Services;
                        </li>
                        <li className="terms-list-item">
                            7. fail to pay for items purchased by you, unless you have a valid
                            reason as set out in any of our policies;
                        </li>
                        <li className="terms-list-item">
                            8. fail to deliver items sold by you (if applicable), unless you
                            have a valid reason as set out in any of our policies;
                        </li>
                        <li className="terms-list-item">
                            9. use contact information provided to you during the course of a
                            transaction on the Site to solicit additional sales offline or on
                            another website;
                        </li>
                        <li className="terms-list-item">
                            10. manipulate the price of any item;
                        </li>
                        <li className="terms-list-item">
                            11. interfere with any other user’s listings;
                        </li>
                        <li className="terms-list-item">
                            12. take any action that may undermine the Site’s feedback and
                            ratings systems;
                        </li>
                        <li className="terms-list-item">
                            13. post false, inaccurate, misleading, deceptive, defamatory or
                            similar content;
                        </li>
                        <li className="terms-list-item">
                            14. transfer your account to another party without our prior written
                            consent;
                        </li>
                        <li className="terms-list-item">
                            15. distribute or post spam, unsolicited or bulk electronic
                            communications or similar;
                        </li>
                        <li className="terms-list-item">
                            16. distribute viruses or any other technologies that may harm our
                            Services or the interests or property of other users;
                        </li>
                        <li className="terms-list-item">17. infringe:</li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. the copyright, trademark, patent, publicity, moral, database,
                                and/or other intellectual property rights (collectively,
                                "Intellectual Property Rights" ) that belong to or are licensed to
                                us; or
                            </li>
                            <li className="terms-sublist-item">
                                2. any Intellectual Property Rights that belong to third parties;
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            18. harvest or otherwise collect information about users without
                            their consent; or
                        </li>
                        <li className="terms-list-item">
                            19. circumvent any technical measures we use to provide the
                            Services.
                        </li>
                    </ul>

                    <h3 className="terms-section-title">3. TRADE IN PROGRAM</h3>
                    <p className="terms-paragraph">
                        Flipdeals in collaboration with any third parties, may offer a
                        trade-in service, wherein you may trade in your eligible electronic
                        device (the “Trade-in Device”) upon the purchase of a new phone device
                        (the “Trade-in Program”). The Trade-in Partner will provide you with
                        an estimated value for the Trade-in Device (the “Trade-In Value”). The
                        Trade-in Value will be credited to your registered Flipdeals account
                        as Flipdeals credit. You can elect to have the Flipdeals credit
                        transferred to your bank account. This Trade-In Program is only
                        available for selected brands and devices purchased online via
                        Flipdeals’s website at www.Flipdeals.com or the Flipdeals App via the
                        Trade- in option.
                    </p>
                    <p className="terms-paragraph">
                        {" "}
                        The Trade-in Program is subject to the following terms set out{" "}
                        <a href="">here</a>
                    </p>

                    <h3 className="terms-section-title">4. INTELLECTUAL PROPERTY RIGHTS</h3>

                    <p className="terms-paragraph">
                        1. Except for the rights expressly granted under these Terms of Use:
                    </p>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. all content included on the Site, including but not limited to
                            text, graphics, logos, images, audio clips, digital downloads and
                            software is our property or the property of our licensors. We (or
                            our licensors, as the case may be) retain all right, title and
                            interest in and to the Site and the Services, including, without
                            limitation, all Intellectual Property Rights therein; and
                        </li>
                        <li className="terms-list-item">
                            2. all rights, title and interest in and to any information,
                            materials or other content that you provide in connection with your
                            use of the Services, including all Intellectual Property Rights
                            therein, will become our property.
                        </li>
                    </ul>

                    <p className="terms-paragraph">
                        2. You agree that you have no right to use any of our trademarks
                        without our prior written consent.
                    </p>

                    <p className="terms-paragraph">
                        3. All rights not expressly granted to you in these Terms of Use are
                        reserved and retained by us or our licensors.
                    </p>

                    <h3 className="terms-section-title">
                        5. WARRANTIES, REPRESENTATIONS & UNDERTAKINGS
                    </h3>
                    <p className="terms-paragraph">
                        You warrant, represent and undertake that:
                    </p>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. You shall fully comply and will at all times continue to fully
                            comply with all applicable laws, statutes and regulations,
                            including, without limitation, all privacy laws and content
                            regulation;
                        </li>
                        <li className="terms-list-item">
                            2. You have full power and authority to enter into these Terms of
                            Use and the execution and performance of your obligations under
                            these Terms of Use does not conflict with:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. any laws, rules, regulations or governmental guidelines to
                                which you are subject to; or
                            </li>
                            <li className="terms-sublist-item">
                                2. any other agreements to which you are a party to or to which
                                you are otherwise bound by;
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            3. If you create or use an account on behalf of a business entity,
                            you represent that you are authorised to act on behalf of such
                            business and bind the business to these Terms of Use. Such account
                            is deemed to be owned and controlled by the business entity;
                        </li>
                        <li className="terms-list-item">
                            4. You own or have the authority to grant the rights and licences
                            granted to us by you under these Terms of Use; and
                        </li>
                        <li className="terms-list-item">
                            5. Any content you submit as part of your use of the Services and
                            any products that you list do not violate the rights of any third
                            party anywhere in the world including, without limitation, any
                            Intellectual Property Rights (whether registered or not).
                        </li>
                        <li className="terms-list-item">
                            2. Subject to clause 5.1, the Services are provided to you on an “as
                            is” basis without representations, warranties or conditions of any
                            kind. We disclaim all warranties, conditions and representations of
                            any kind, whether express, implied or collateral, including, but not
                            limited to, all conditions, representations or warranties of
                            merchantability, of fitness for a particular or general purpose, of
                            non-infringement, of compatibility or that the Services are secure
                            or error free or will operate without interruption or will be
                            provided in a timely or proper manner or at all.
                        </li>
                        <li className="terms-list-item">
                            3. Furthermore, whilst we attempt to be as accurate as possible, we
                            do not warrant that product descriptions or other content of any
                            Service is accurate, complete, reliable, current, or error-free.
                            Additionally, as a buyer, you agree that we are not responsible for
                            examining or warranting the listings or content provided by us or
                            third parties through the Services, and that you will not attempt to
                            hold us liable for any inaccuracies or defects in any of the
                            listings. As a seller, it is your responsibility to review the
                            content of your listings for accuracy and you will not attempt to
                            hold our catalogue/content providers or us responsible for
                            inaccuracies.
                        </li>
                    </ul>
                    <h3 className="terms-section-title">6. LIABILITY & INDEMNITIES</h3>

                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. Nothing in these Terms of Use shall limit or exclude a party’s
                            liability:
                        </li>

                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. for fraud, including fraudulent misrepresentation, perpetrated
                                by that party;
                            </li>
                            <li className="terms-sublist-item">
                                2. for death or personal injury caused by the negligence of that
                                party; or
                            </li>
                            <li>
                                3. for any other liability that cannot be limited or excluded
                                under applicable law
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            2. Subject to clause 5.1, in no event will we, our parent company,
                            subsidiaries and affiliates, and our, and their directors, officers,
                            agents, employees, suppliers, subcontractors or licensors be liable,
                            whether based on an action or claim in contract, tort, negligence,
                            breach of statutory duty or otherwise arising out of or in relation
                            to these Terms of Use for loss of profits, loss of data or
                            information, business interruption or other pecuniary loss or for
                            any special, indirect, incidental or consequential damages, even if
                            we, our affiliates, directors, officers, agents, employees,
                            licensors, subcontractors or suppliers have been advised of the
                            possibility of such damages.
                        </li>
                        <li className="terms-list-item">
                            3. In addition, to the extent permitted by applicable law, we
                            (including our parent company, subsidiaries and affiliates and our,
                            and their directors, officers, agents, employees, suppliers,
                            subcontractors or licensors) are not liable, and you agree not to
                            hold us responsible, for any damages or losses resulting directly or
                            indirectly from:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. the content or other information you provide when using the
                                Services;
                            </li>
                            <li className="terms-sublist-item">
                                2. your use of or your inability to use our Services;
                            </li>
                            <li>
                                3. pricing, shipping, format or other guidance provided by us;
                            </li>
                            <li>4. delays or disruptions in our Services;</li>
                            <li>
                                5. viruses or other malicious software obtained by accessing or
                                linking to our Services;
                            </li>
                            <li>
                                6. bugs, errors or inaccuracies of any kind in our Services;
                            </li>
                            <li>
                                7. damage to your hardware device from the use of products sold on
                                the Site or our Services;
                            </li>
                            <li>
                                8. the content, actions or inactions of third parties using our
                                Services;
                            </li>
                            <li>
                                9. a suspension or other action taken by us with respect to your
                                use of the Services;
                            </li>
                            <li>
                                10. the duration or manner in which your listings appear in search
                                results; or
                            </li>
                            <li>
                                11. your need to modify practices, content or behaviour or your
                                loss of or inability to do business as a result of changes to
                                these Terms of Use.
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            4. Subject to clause 5.1, if clauses 5.2 or 5.3 are held to be
                            unenforceable or inapplicable for any reason, then the total
                            liability applicable to us, our parent company, subsidiaries and
                            affiliates and our, and their directors, officers, agents, employee,
                            suppliers, subcontractors or licensors, to you, whether based on an
                            action or claim in contract, negligence or breach of statutory duty
                            or otherwise, arising out of or in relation to these Terms of Use
                            shall be limited to the lower of:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. the price the item sold for on our Site and its original
                                shipping costs; and
                            </li>
                            <li className="terms-sublist-item">
                                2. the amount of fees in dispute not to exceed the total fees that
                                you paid to us in the twelve (12) months prior to the action
                                giving rise to the liability; or
                            </li>
                            <li className="terms-sublist-item">3. SAR 300.</li>
                        </ul>
                        <li className="terms-list-item">
                            5. You agree to indemnify and hold us, our parent company,
                            subsidiaries and affiliates and our, and their directors, officers,
                            agents, employee, suppliers, subcontractors or licensors harmless
                            from and against any losses, damages and expenses (including legal
                            fees and attorney’s fees) (“Claims”) arising out of or relating to:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. any claims or demands made by any third party due to or arising
                                out of your use of the Services;
                            </li>
                            <li className="terms-sublist-item">
                                2. your violation of any of the provisions of these Terms of Use,
                                including, without limitation, any of the warranties,
                                representations and undertakings;
                            </li>
                            <li className="terms-sublist-item">
                                3. your violation of any applicable laws, including, without
                                limitation, data protection or anti-spam laws; or
                            </li>
                            <li className="terms-sublist-item">
                                4. the manner in which you use our Services, including, without
                                limitation, that the content you post, the items you list or your
                                trademarks infringe the Intellectual Property Rights of any third
                                party or that the content of your listings is slanderous,
                                defamatory, obscene or violates any other rights (including
                                privacy rights) of any third party (including other Site users).
                            </li>
                        </ul>
                    </ul>
                    <h3 className="terms-section-title">
                        7. SUSPENSION, TERMINATION & CANCELLATION
                    </h3>
                    <ul className="terms-list">
                        <li className="terms-list-item">
                            1. We may, at our sole discretion and without prejudice to any of
                            our rights and remedies and without any liability to you, we may
                            limit, suspend or permanently withdraw your access of our Services,
                            cancel any product(s) order and/or remove hosted content submitted
                            by you for any reason including, without limitation, the following:
                        </li>

                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. if we believe that you have infringed, breached, violated,
                                abused, or unethically manipulated or exploited any term of these
                                Terms of Use;
                            </li>
                            <li className="terms-sublist-item">
                                2. If you use our Platform or Services for any unlawful and
                                fraudulent purposes or in connection with a criminal offence or
                                abuse our policy and rules;
                            </li>
                            <li className="terms-sublist-item">
                                3. You are suspected of inventory abuse or placing bulk order. The
                                following shall (without limitation) constitute inventory abuse or
                                placing bulk order:
                            </li>
                            <li className="terms-sublist-item">
                                3.1. Products ordered are not for self-consumption but for
                                commercial resale;
                            </li>
                            <li className="terms-sublist-item">
                                3.2. Multiple orders placed for same product at the same address,
                                depending on the product category;
                            </li>
                            <li className="terms-sublist-item">
                                3.3. Bulk quantity of the same product ordered
                            </li>
                            <li className="terms-sublist-item">
                                3.4. Invalid address given in order details;
                            </li>
                            <li className="terms-sublist-item">
                                3.5. Any abuse or fraud used to place the order; or
                            </li>
                            <li className="terms-sublist-item">
                                3.6. Any order placed using a technological glitch/loophole.
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            2. We reserve our right to take appropriate action if you are
                            suspected of acting fraudulently or if you file invalid and/or false
                            claims or provides false, incomplete, or misleading information. You
                            may be considered to be engaging in fraudulent activities if any of
                            the following (without limitation) are met:
                        </li>
                        <ul className="terms-sublist">
                            <li className="terms-sublist-item">
                                1. you don’t reply to the payment verification mail sent by us;
                            </li>
                            <li className="terms-sublist-item">
                                2. you fail to produce adequate documents during the payment
                                details verification;
                            </li>
                            <li className="terms-sublist-item">
                                3. you misuse credentials not belonging to you;
                            </li>
                            <li className="terms-sublist-item">
                                4. you use invalid address, email and phone no;
                            </li>
                            <li className="terms-sublist-item">
                                5. you attempt to overuse a voucher code;
                            </li>
                            <li className="terms-sublist-item">
                                6. you return wrong or damaged product;
                            </li>
                            <li className="terms-sublist-item">
                                7. you refuse to pay for an order;
                            </li>
                            <li className="terms-sublist-item">
                                8. for COD items, you use fake currency notes to make the payment;
                            </li>
                            <li className="terms-sublist-item">
                                9. you abuse or harass the delivery staff;
                            </li>
                            <li className="terms-sublist-item">
                                10. miscellaneous activities conducted with the sole intention to
                                cause loss to business/revenue to Flipdeals;
                            </li>
                            <li className="terms-sublist-item">
                                11. your return, undeliverable rate is very high and often the
                                returned products are missing, fake or damaged; or
                            </li>
                            <li className="terms-sublist-item">
                                12. Repeated request for monetary compensation
                            </li>
                        </ul>
                        <li className="terms-list-item">
                            3. If your access to our Services or our Platform is terminated for
                            any reason, we may delete any content or other materials relating to
                            your use of the Service (including any credits earned through
                            loyalty programs or reward points) and we will have no liability to
                            you or any third party for doing so. However, your transactions
                            details may be preserved by us for purposes of tax or regulatory
                            compliance.
                        </li>
                        <li className="terms-list-item">
                            4. For the avoidance of doubt, any amounts paid and received by us
                            in relation to a cancelled product(s) order will be refunded.
                        </li>
                    </ul>
                    <h3 className="terms-section-title">
                        8. REPORTING VIOLATIONS OF THESE REQUEST TERMS OF USE
                    </h3>
                    <p className="terms-paragraph">
                        1. We are committed to ensuring that listed items and content on our
                        Site comply with these Terms of Use. If you believe that a listed item
                        or content breaches these Terms of Use, please notify us on the
                        details in clause 8.12 and we will investigate.
                    </p>
                    <h3 className="terms-section-title">
                        9. FLIPDEALS AFFILIATES & ADDITIONAL FUNCTIONS
                    </h3>
                    <p className="terms-paragraph">
                        1. Flipdeals E Commerce Solutions One Person Company LLC and/or its
                        affiliates ("Flipdeals’s Affiliates") provide website features and
                        other products and services to you when you use or sign-up as a buyer
                        and/or seller on the Site. “Affiliate” means, with respect to a
                        particular person, any entity that directly or indirectly controls, is
                        controlled by, or is under common control with such person.
                    </p>
                    <p className="terms-paragraph">
                        2. To enhance your experience across the Site and with Flipdeals’s
                        Affiliates, you hereby agree that we may set-up additional services,
                        functions and/or accounts on your behalf, by using the information you
                        provide to us on the Site.
                    </p>
                    <h3 className="terms-section-title">10. GENERAL</h3>
                    <p className="terms-paragraph">
                        <span className="txtbold">1. Governing Law.</span> These Terms of Use
                        and any non-contractual rights or obligations arising out of or in
                        connection with it shall be governed by and construed in accordance
                        with the applicable laws of KSA.
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold">2. Dispute Resolution. </span>If you have an
                        issue with our Services, please contact us. We will endeavour to
                        resolve your issue as soon as possible. Any disputes or Claims arising
                        out of or in connection with these Terms of Use, including any
                        non-contractual rights or obligations arising out of or in connection
                        with these Terms of Use shall be referred to and finally resolved by
                        KSA courts.
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">3. Third Party Rights.</span> A person who
                        is not a party to these Terms of Use has no right to enforce any of
                        its terms.
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold"> 4. Relationship of the Parties.</span>{" "}
                        Nothing contained in these Terms of Use will be deemed or construed by
                        the parties or any third party to create the relationship of
                        partnership, joint venture or agency between the parties, it being
                        understood that the parties will at all times remain independent
                        parties contracting for Services.
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">5. Further Assurances.</span> The parties
                        will do and execute or arrange for the doing and executing of each
                        necessary act, document and thing reasonably within its power to
                        implement and give effect to these Terms of Use to its full extent,
                        including, without limitation, assisting each other in complying with
                        applicable law.
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold">6. Assignment.</span> These Terms of Use
                        will be binding upon and enure to the benefit of the parties and their
                        respective successors and permitted assigns. You agree that you will
                        not assign or transfer these Terms of Use or any of your rights or
                        obligations under these Terms of Use, whether directly or indirectly,
                        without first obtaining our prior written consent, such consent not to
                        be unreasonably withheld.
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold"> 7. Entire Agreement.</span> These Terms of
                        Use and the documents referred to or incorporated herein by reference
                        contain the entire agreement between the parties with respect to the
                        subject matter and supersede all prior agreements, negotiations and
                        representations, written or oral, relating to its subject matter.
                        Except as provided in these Terms of Use and the documents referred to
                        or incorporated into these Terms of Use by reference, there are no
                        conditions, representations, warranties, undertakings or agreements
                        between the parties whether direct, indirect, collateral, express or
                        implied.
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold">8. Amendment.</span> These Terms of Use
                        cannot be modified, varied, amended or supplemented in any way by you.
                        We reserve the right to modify, vary, amend or supplement these Terms
                        of Use at any time and from time to time. We will post the current
                        version of these Terms of Use on the Site and each such change will be
                        effective upon posting on the Site or upon the date designated by us
                        as the “effective date” (if any). Your continued use of the Services
                        following any such change constitutes your agreement to be bound by
                        and its acceptance of these Terms of Use as so modified.
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold">9. Severability.</span> If any provision of
                        these Terms of Use is determined by any court of competent
                        jurisdiction to be invalid, illegal or unenforceable, that provision
                        will be severed from these Terms of Use and the remaining provisions
                        will continue in full force and effect so long as the economic or
                        legal substance of the transactions contemplated hereby is not
                        affected in any manner materially adverse to either of the parties.
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">10. Force Majeure.</span> Neither party will
                        be liable for any loss or damage or for any delay or failure in
                        performance due to acts beyond the control of such party whether or
                        not such acts could reasonably be anticipated (including acts of God,
                        legislative, judicial or regulatory acts of any provincial or the
                        federal government, court or regulatory authority, acts of any of our
                        subcontractors or any third party providers of goods or Services to
                        us, labour disruptions, blackouts, embargoes).
                    </p>

                    <p className="terms-paragraph">
                        <span className="txtbold"> 11. No Waiver. </span> Any waiver by us of
                        any of the provisions of these Terms of Use will not constitute a
                        waiver of any other provision (whether similar or not), nor will any
                        such waiver constitute a continuing waiver ofthat particular
                        provision, unless expressly provided by us in writing.
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold"> 12. Communications.</span> You can reach
                        out to us by, going to the 'Account' section, click on 'Need Help?'
                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">13. Survival. </span> All provisions that
                        either expressly or by their nature survive, will survive suspension
                        or termination of your membership of the Site.
                    </p>
                    <h3 className="terms-section-title">
                        11. CROSS BORDER (GLOBAL)
                    </h3>
                    <p className="terms-paragraph">
                        1. These terms and conditions are in addition to the standard Terms of Sale and
                        Terms of Use on our website and mobile applications. Title and risk of loss for
                        the items transfers to the recipient upon delivery to the carrier in the [UAE/
                        outside the destination country].


                    </p>

                    <p className="terms-paragraph">
                        2. Each item in your order is sold by Bandidos Retail LLC, a subsidiary in the
                        Flipdeals group ("Flipdeals") or the seller that the item is specified as sold by
                        ("Seller").



                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">3. Import Charges </span>
                    </p>
                    <p className="terms-paragraph">
                        1. Those items for shipment to countries outside of the United Arab
                        Emirates (UAE) may be subject to taxes, customs duties, government
                        charges and any other costs levied by the destination country
                        ("ImportFee"). When purchasing International Products, you acknowledge
                        that the sale occurs outside of the destination country and that you are
                        importing the products for your personal use, which will be reflected on
                        customs documentation. The recipient of the shipment is the importer of
                        record in the destination country and is responsible for all Import Charges



                    </p>
                    <p className="terms-paragraph">
                        2. Further, in the event you wish to receive the order in the Kingdom of
                        Saudi Arabia (KSA) you shall provide proof of residence, if any on-demand
                        and may be subject to the legal requirements of KSA including but not
                        limited to Iqama validation.




                    </p>
                    <p className="terms-paragraph">
                        3. With respect to each item for which Import Charges have been calculated,
                        you authorize Flipdeals or Seller (as applicable) to designate a carrier or
                        agent ("Designated Carrier") to act as your direct or indirect
                        representative/ agent with the relevant customs and tax authorities in the
                        destination country, to clear your merchandise, process and remit your
                        actual Import Charges for such item and and provide any other information
                        pertaining to you or your order to any governmental authority in the
                        destination country or abroad, as may be requested.



                    </p>
                    <p className="terms-paragraph">
                        4. "Import Charges Deposit" represents an estimate of the Import Charges
                        that will be levied on the items in your order for shipment to countries
                        outside of the UAE. By placing your order, you agree to allow Flipdeals
                        and/or Seller (as applicable) to collect the Import Charges Deposit for the
                        applicable items in your order. This fee will be used, on your behalf, to
                        reimburse the Designated Carriers for the Import Charges that they have
                        paid on your behalf to the appropriate authorities of the destination
                        country.



                    </p>
                    <p className="terms-paragraph">
                        5. You further agree that the Designated Carriers may disclose to Flipdeals
                        or Seller (as applicable) the amount of actual Import Charges along with
                        any other charges incurred by Flipdeals or Seller to make the order
                        available to you outside UAE, levied on the item you have purchased from
                        Flipdeals and/or Seller.


                    </p>
                    <p className="terms-paragraph">
                        6. In the case of gifts or purchases made on behalf of another recipient, you
                        also agree to grant the foregoing authorizations on behalf of the recipient
                        designated in your order.



                    </p>
                    <p className="terms-paragraph">
                        7. Imports are subject to opening and inspection by customs and/or postal
                        authorities. We may provide certain order, shipment, and product
                        information, such as product names or titles, to the Designated Carriers,
                        and such information may be communicated by the Designated Carriers to
                        customs and/or postal authorities in order to facilitate customs clearance
                        and comply with local laws. Customs authorities require the value of the
                        gift item to be stated directly on the package. Therefore, even if the order
                        is a gift, the package will be marked "Gift," but the cost of the item will be
                        stated on the customs form.



                    </p>
                    <p className="terms-paragraph">
                        8. To obtain details regarding the Import Charges, or to obtain
                        documentation or receipts in connection with customs clearance, you may
                        contact the Designated Carrier specified in your shipment confirmation.



                    </p>
                    <p className="terms-paragraph">
                        9. We will require you to provide us with your ID number and full name to
                        ensure smooth customs clearance and prompt delivery of your order.
                        Acceptable documents include your National ID name and number, or your
                        name and number on any government-issued document providing proof of
                        residence. Please note that the name on the ID card must match the name
                        used for the order. For more information, you can visit this link to the  <a href="">Saudi Customs website.</a>





                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold"> 4. Returns and Refund</span>
                    </p>
                    <p className="terms-paragraph">
                        1. In case of return of the items, in addition to the existing return conditions
                        applicable to such items that are available on our website and mobile
                        applications, you acknowledge that you will not receive any refund for the
                        Import Charges.



                    </p>
                    <p className="terms-paragraph">
                        2. When you request to return an item, the Seller may (at its sole discretion)
                        elect to issue a refund without requiring a return, or require that you
                        return the product to an address located either inside the KSA or, if
                        different, your delivery country ("Local Return Destination").



                    </p>
                    <p className="terms-paragraph">
                        3. If you are required to return the item to a Local Return Destination,
                        Flipdeals will decide (in its sole discretion) whether to forward the item to
                        an address that is outside both KSA and your delivery country
                        ("International Return Destination"), or otherwise how to handle it. By
                        initiating the returns process, you expressly authorize Flipdeals to handle
                        the returned product on your behalf. If the returned product is ultimately
                        forwarded to the International Return Destination, you will be responsible
                        for clearing the goods (as exporter of record), and you authorize us to do
                        so on your behalf by allowing Flipdeals to designate a carrier who will act
                        as your direct or indirect representative/agent with the relevant customs
                        and tax authorities in the country of exportation



                    </p>
                    <p className="terms-paragraph">
                        8. To obtain details regarding the Import Charges, or to obtain
                        documentation or receipts in connection with customs clearance, you may
                        contact the Designated Carrier specified in your shipment confirmation.



                    </p>
                    <p className="terms-paragraph">
                        <span className="txtbold">5. General
                        </span>
                    </p>
                    <p className="terms-paragraph">
                        1. Please note that Sellers may have policies that differ from Flipdeals’s
                        terms and conditions. For items you have purchased from a Seller, please
                        see the applicable Seller’s policies for any other terms and conditions that
                        may apply to your purchase of such items, including the warranty of such
                        items.



                    </p>
                    <p className="terms-paragraph">
                        2. International items are marketed, distributed, and sold in the applicable
                        source country listed in the above section but made available to you
                        through Flipdeals. Consequently, some international items may not be
                        designed to the same specifications as products of the destination country
                        and may differ from versions sold in the destination country, including in
                        their fit, default settings, age ratings, and language. Foreign versions of
                        certain items, such as software and phones, may be region-locked against
                        use in the destination country. Manufacturer warranties may not apply in
                        the destination country and manufacturer service options may not be
                        available. You are responsible for reviewing available product information
                        to ensure that the international item you are purchasing meets your needs
                        and expectations.




                    </p>
                    <p className="terms-paragraph">
                        3. To the extent permitted by law, these terms are governed by and
                        construed in accordance with the laws of the Dubai International Finance
                        Center ("DIFC"). Any dispute shall be finally settled by arbitration under
                        the Arbitration Rules of the Dubai International Arbitration Centre, which
                        Rules are deemed to be incorporated by reference into this clause. The
                        number of arbitrators shall be one. The seat, or legal place, of arbitration
                        shall be theDIFC. The language to be used in the arbitration shall be English.



                    </p>
                    <p className="terms-paragraph">
                        4. We reserve the right to make changes to these terms and conditions at
                        any time by posting the changes on our website. You will be subject to the
                        terms, conditions and policies in force at the time that you purchase the
                        items.



                    </p>
                    <p className="terms-paragraph">
                        5. The Arabic version of these terms and conditions was translated from
                        English. The English language version is the definitive version. In case of
                        inconsistency or ambiguity between the Arabic text and the English text,
                        the English text will prevail.



                    </p>
                    {/* Continue adding all other sections following the same structure */}
                </div>
            </section>

        </div>
    )
}

export default TermsAndConditions