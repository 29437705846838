import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ApiNames from '../../Constants/ApiUrls';
import Axios from "../../utils/AxiosConfi";
import Toaster from '../../utils/Toaster';
import { useCart } from '../../Context/CartContext';

const PayVerification = () => {
    // const { resultIndicator } = useParams();
    const [ref, setRef] = useState('');
    const [getError, setError] = useState('');
    const modalRef = useRef(null);
    const [isLoadingCount, setLoadingCount] = useState(true);
    let currentRate = useRef(0)
    const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart();
    // const location = useLocation();
    let navigation = useNavigate();
    // const resultIndicator = location.search;

    useEffect(() => {
        console.log('useEffect called');
        getInfo();
    }, []);

    const getInfo = async () => {
        debugger
        const urlParams = new URLSearchParams(window.location.search);
        const refParam = urlParams.get('ref');
       
       
        let info = {
            number: refParam
        };
        currentRate.current = 2
        // let api = ApiNames.paymentVerification;
        let api = ApiNames.paymentVerificationWithNetwork;
        try {
             
            const response = await Axios.post(`${api}`, info);
            if (response.data.status === 'CAPTURED') {
                Toaster(response.data.message);
                setLoadingCount(false);
                navigation(`/Orders`);
                addToCart('');
                setTimeout(()=>{
                  setLoadingCount(false);
                },100)
            }
        
        } catch (err) {
            Toaster(err.response?.data?.error || 'An error occurred');

            setError(err.response?.data?.error || 'An error occurred')
            // navigation(`/CHeckout`);
            openModal()
        }
    };


    const openModal = () => {
        // Import the modal from Bootstrap
        if(modalRef.current){
            modalRef.current.click()
        }
      };

      const closeANdNavigate = () =>{
         navigation(`/CHeckout`);
      }
    
    return (
        <div className='PaymentVerification'>
            <div className="spinner-border" role="status"></div>
            <span className="visually-hidden">Booking order</span>
            <>
  {/* Button trigger modal */}
  <button
    type="button"
    ref={modalRef}
    className="btn btn-primary"
    style={{opacity:0}}
    data-bs-toggle="modal"
    data-bs-target="#staticBackdrop"
  >
    
  </button>
  {/* Modal */}
  <div
    className="modal fade"
    id="staticBackdrop"

    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
          <i style={{color:'red'}} class="fas fa-times-circle"></i> Transaction Failed
          </h5>
          {/* <button
            type="button"
            className="btn-close"
          /> */}
        </div>
        <div className="modal-body">{getError}</div>
        <div className="modal-footer">
          {/* <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button> */}
          <button type="button" onClick={closeANdNavigate} data-bs-dismiss="modal" className="btn closreBrn">
            Understood
          </button>
        </div>
      </div>
    </div>
  </div>
</>

        </div>
    );
};

export default PayVerification;
