import React, { useEffect, useState } from 'react'
import Axios from "../../utils/AxiosConfi";
import ApiNames from "../../Constants/ApiUrls";
import { useCart } from '../../Context/CartContext';
import { Link, useNavigate } from "react-router-dom";
const WishItems = () => {
    const navigate = useNavigate();
    const { addToCart } = useCart()
    const [getItems, setItems] = useState([]);
    useEffect(() => {
        const getMoreReason = async () => {
            try {
                let api = ApiNames.getWishList
                const response = await Axios.get(
                    `${api}`
                );

                setItems(response.data.wishList);

            } catch (error) {
                console.log(error);
            }
        };
        getMoreReason();
    }, []);
    function addToCarts(product) {
        // navigate(`/ProductView/${id}`)
        let splitName =product.title.replace(/\s+/g, "-");
        navigate(`/ProductView/${splitName}/${product._id}`)
    }


    function openNewTab(product) {
        debugger
        let splitName = product.title.replace(/\s+/g, "-");
        window.open(`/ProductView/${splitName}/${product._id}`, '_blank');
      }


    function gotoHome() {
        navigate(`/landing`)
    }

    function fixtheNuber(data) {
        if (data !== undefined && data !== null) {
          const numericData = parseFloat(data); // Convert input string to a number
          if (!isNaN(numericData)) { // Check if the conversion was successful
            const roundedValue = Math.round(numericData); // Round to nearest integer
            return roundedValue; // Format to two decimal places
          }
        }
        return ''; // Or any other default value or behavior you prefer
      }
    async function deleteCart(id) {
        try {

            let api = ApiNames.deleteWishList
            const response = await Axios.get(
                `${api}${id}`
            );
            addToCart("")
            let apsi = ApiNames.getWishList
            const responseNew = await Axios.get(
                `${apsi}`
            );

            setItems(responseNew.data.wishList);

        } catch (error) {
            console.log(error);
        }
    }



    return (

        <div>

            {getItems.length > 0 ? (
                <div>

                    <div className='wishlist-page-top'>
                        <div className='container'>
                            <h1 className='wishlist-title' > <span onClick={gotoHome}>Home /</span> Wishlist</h1>

                            <div className="row row-cols-2  row-cols-md-4 row-cols-lg-5 g-3">
                                {getItems.map((products, index) => (
                                    <div key={index} className="col">
                                        <div className="card product-card">
                                            <div className="card-top">
                                                <Link
                                                style={{ textDecoration: 'none' }}
                                                to={`/ProductView/${products?.productDetails[0]?.title.replace(/[\s/]+/g, "-")}/${products?.productDetails[0]?._id}`}
                                                
                                                className="productImg-top">
                                                    <img 
                                                
                                                    
                                                        src={products.productDetails[0]?.thumbnail}
                                                        className="card-img-top product-cardImage"
                                                        alt="..."
                                                    />
                                                </Link>
                                                {/* <div className="DiscoutBox">
                                                    <div>
                                                        <span className="dicsoutnNu"> {products?.productDetails[0]?.discount}% <br />OFF</span>
                                                    </div>
                                                </div> */}
                                                <div>
                                                    {
                                                        products.isWishlist ? (
                                                            // <img onClick={() => (deleteCart(products._id))}
                                                            //     src="/images/LandingPg/cancel.svg"
                                                            //     alt=".."
                                                            //     className="wishlist-icon"
                                                            // />
                                                            <i className="fas fa-trash-alt"></i>
                                                        ) : (
                                                            <img onClick={() => (deleteCart(products._id))}
                                                                src="/images/LandingPg/delete.svg"
                                                                alt=".."
                                                                className="wishlist-iconNew"
                                                            />
                                                            // <i onClick={() => (deleteCart(products._id))} className="fas fa-trash-alt wishlist-icon"></i>
                                                        )
                                                    }


                                                </div>
                                            </div>
                                            <Link
                                            
                                            style={{ textDecoration: 'none' }}
                                            to={`/ProductView/${products?.productDetails[0]?.title.replace(/[\s/]+/g, "-")}/${products?.productDetails[0]?._id}`}
                                            className="card-body product-body">
                                                <p  className="card-title item-title">{products.productDetails[0]?.title}</p>
                                                <div className="item-review">
                                                    <span className="starss">
                                                        &#9734;&#9734;&#9734;&#9734;&#9734;
                                                    </span>
                                                    <span className="custemer-rivew">400 </span>
                                                </div>
                                                <p className="item-price">
                                                    AED <span>{fixtheNuber(products.productDetails[0]?.sellingPrice)}</span>
                                                </p>
                                                <button  className="btn add-cart">
                                                    <img
                                                        src="/images/LandingPg/bagIcn.svg"
                                                        alt=".."
                                                        className="bag-icon"
                                                    />
                                                    Add To Cart
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='wishlist-page-top'>
                        <div className='container'>
                            <h1 className='wishlist-title' ><span onClick={gotoHome}>Home</span> / Wishlist</h1>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70vh' }}>
                                <h1 className='emptylist'>Wishlist is Empty</h1>
                            </div>

                        </div>
                    </div>
                </div>
            )}





        </div>



    )
}

export default WishItems