import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import ApiNames from '../../Constants/ApiUrls';
import Axios from "../../utils/AxiosConfi";
import Toaster from '../../utils/Toaster';

const CreateAddress = () => {
    const [getName, setName] = useState('');
    const selectCountryNow = useRef(null)
    const [selectedCountryFlag, setSelectedCountryFlag] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjExNEYxQzE3OEExMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBMjExNEYxRDE3OEExMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyMTE0RjFBMTc4QTExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyMTE0RjFCMTc4QTExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VISheQAAAGRJREFUeNpiPCeo9p+BCGDUeYuBmoCJYYDAqMWjFtMMsBCr8H/c/98M7AzMQCYjfX38/QsjVD0jNTDRFv/592eEJS5mZuaBSVyM/LygMv0ftRIX8RYzMrKOFiCjFo9ajA8ABBgAlQMOS8Um6UkAAAAASUVORK5CYII=');
    const [selectedCountryCode, setSelectedCountryCode] = useState('971');
    const [getCounties, setCounties] = useState([]);
    const [getCountry, setCountry] = useState([]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            countryCode: '',
            address: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            emailId: "",
            type: "",
        },
        onSubmit: async (values) => {
            try {
                let api = ApiNames.createAddress;
                values.countryCode = selectedCountryCode
                const response = await Axios.post(api, values);
                console.log(response.data)
                Toaster(response.data.message);
                formik.resetForm()
                setTimeout(() => {
                    document.dispatchEvent(new Event('customAddressEvent'));
                }, 100)
            } catch (err) {
                if (err.error.message) {
                    Toaster(err.error.message);
                } else {
                    Toaster(err.response?.data?.message);
                }
            }
        },
        validate: (values) => {
            let errors = {}

            if (!values.firstName) {
                errors.firstName = 'First Name is required'
            }
            if (!values.lastName) {
                errors.lastName = 'Last Name is required'
            }
            if (!values.mobileNumber) {
                errors.mobileNumber = 'Mobile number is required'
            }
            if (values.mobileNumber) {
                const mobileNumberString = values.mobileNumber.toString()
                if (mobileNumberString.length > 14) {
                    errors.mobileNumber = 'Invalid Mobile number'
                }

            }
            console.log(values.mobileNumber.length)
            if (!values.address) {
                errors.address = 'Address is required'
            }
            if (!values.city) {
                errors.city = 'City is required'
            }
            if (!values.state) {
                errors.state = 'State is required'
            }
            if (!values.country) {
                errors.country = 'Country is required'
            }
            if (!values.pincode) {
                errors.pincode = 'Pincode is required'
            }
            if (!values.emailId) {
                errors.emailId = 'Email is required'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailId)) {
                errors.emailId = 'Invalid email address'
            }
            if (!values.type) {
                errors.type = 'Type is required'
            }

            return errors
        }
    })

    const selectCOuntryS = () => {
         
        selectCountryNow?.current.focus()
    }
    const handleCloseDropdown = (value) => {
        const dropdownMenu = document.getElementById('dropdownMenuButton1');
        dropdownMenu.setAttribute('aria-expanded', 'false');
        dropdownMenu.classList.remove('show');
        // const dropdown = dropdownMenu.nextElementSibling;
        // dropdown.classList.remove('show');
        console.log(value)
        setSelectedCountryFlag(`data:image/png;base64,${value?.flag}`)
        setSelectedCountryCode(value?.callingCode)
    };
    const handleKeyUp = (event) => {
        setName(event.target.value)
    }
    useEffect(() => {
        const getProfileDetails = async () => {
            try {

                let api = ApiNames.Inssearch
                const response = await Axios.post(
                    `${api}`, { name: getName }
                );
                setCounties(response.data)

            } catch (error) {
                console.error("Error checking login status:", error);
            }
        }
        getProfileDetails()
    }, [getName])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${ApiNames.getAllCountries}`);
                setCountry(response.data);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        fetchData();
    }, []);


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         let api =ApiNames.createAddress;


    //         setValue(true);
    //         getProfile();
    //         Toaster(response.data.message);
    //     } catch (error) {
    //         Toaster(error.response.data.error);
    //         console.error('Error uploading image:', error);
    //     }
    // };





    return (
        <div className='addAddressComponent'>
            <form onSubmit={formik.handleSubmit} autoComplete='off'>
                <div className='row'>
                    <div className="mb-3">
                        <input type="text" placeholder='First Name'
                            className="form-control" name='firstName' id='firstName'
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? <div className='errorName'>{formik.errors.firstName}</div> : null}
                    </div>
                    <div className="mb-3">
                        <input type="text" placeholder='Last Name'
                            className="form-control" name='lastName'
                            id='lastName' value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        {formik.touched.lastName && formik.errors.lastName ? <div className='errorName'>{formik.errors.lastName}</div> : null}
                    </div>
                    <div className="mb-3">
                        <div className="dropdown getMobile">
                            <button onClick={selectCOuntryS} className="btn btn-secondary dropdown-toggle mobileDrop" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='imgDrop' src={selectedCountryFlag} /> <label>{selectedCountryCode}</label>
                            </button>
                            <input type="number" placeholder='Mobile No'
                                className="form-control" name='mobileNumber'
                                id='mobileNumber' value={formik.values.mobileNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <div className='searchBarDesign'>
                                    <input type='text' className='searchBox' ref={selectCountryNow} onKeyUp={handleKeyUp} placeholder='Search country' />
                                    {getCounties.map((value, index) => (
                                        <li className='liItems' onClick={() => handleCloseDropdown(value)} key={index}><a className="dropdown-item" >
                                            <img
                                                src={`data:image/png;base64,${value.flag}`}
                                                alt=""
                                                className="sregistration-image"
                                            />
                                            <label className='countryName'>  {value?.name}</label>

                                        </a></li>
                                    ))}

                                </div>

                            </ul>
                        </div>
                        {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='errorName'>{formik.errors.mobileNumber}</div> : null}

                    </div>
                    <div className="mb-3">
                        <input type="text" placeholder='Address (House No, Building, Street, Area)*'
                            name='address' id='address' className="form-control"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.address && formik.errors.address ? <div className='errorName'>{formik.errors.address}</div> : null}
                    </div>
                    <div className="mb-3">
                        <input type="text" placeholder='City'
                            className="form-control" name='city' id='city'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.city && formik.errors.city ? <div className='errorName'>{formik.errors.city}</div> : null}
                    </div>
                    <div className="mb-3">
                        <input type="text" placeholder='State '
                            className="form-control" name='state' id='state'
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.state && formik.errors.state ? <div className='errorName'>{formik.errors.state}</div> : null}
                    </div>
                    <div className="mb-3">
                        <select className="form-select" aria-label="Default select example" onChange={formik.handleChange} name='country' id='country' value={formik.values.country} onBlur={formik.handleBlur}>
                            <option value="" label="Select country" />
                            {getCountry.map((values, index) => (
                                <option value={values.name}>{values.name}</option>
                            ))}

                        </select>
                        {formik.touched.country && formik.errors.country ? <div className='errorName'>{formik.errors.country}</div> : null}
                    </div>
                    <div className="mb-3">
                        <input type="number" placeholder='Pin Code ' onChange={formik.handleChange} className="form-control" name='pincode' id='pincode' value={formik.values.pincode} onBlur={formik.handleBlur} />
                        {formik.touched.pincode && formik.errors.pincode ? <div className='errorName'>{formik.errors.pincode}</div> : null}
                    </div>
                    <div className="mb-3">
                        <input type="text" placeholder='Email ' onChange={formik.handleChange} className="form-control" name='emailId' id='emailId' value={formik.values.emailId} onBlur={formik.handleBlur} />
                        {formik.touched.emailId && formik.errors.emailId ? <div className='errorName'>{formik.errors.emailId}</div> : null}
                    </div>
                    <div className="mb-3">
                        <select className="form-select" aria-label="Default select example" onChange={formik.handleChange} name='type' id='type' value={formik.values.type} onBlur={formik.handleBlur}>
                            <option value="" label="Select type" />
                            <option value="Home">Home</option>
                            <option value="Office">Office</option>
                            <option value="Other">Other</option>
                        </select>
                        {formik.touched.type && formik.errors.type ? <div className='errorName'>{formik.errors.type}</div> : null}
                    </div>
                </div>
                <div className='delevryBtns'>
                    <button type="submit" className="btn btn-primary submitButn">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default CreateAddress
