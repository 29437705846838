

// import React, { useState, useEffect } from "react";

import { useEffect, useState } from "react";
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import { useCart } from "../../Context/CartContext";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Toaster from "../../utils/Toaster";
import MySkeleton from "../../Component/Loaders/MySkeleton";

const ProductItemsNew = (props) => {
  const [onDataReturn, setonDataReturn] = useState('');
  const [isLoginUser, setIsLogin] = useState(null);
  const navigate = useNavigate();
  const { addToCart, whenLoginRefreshProduct, cartItems } = useCart()


  async function addOrRemoverWishList(product) {

    let token = await localStorage.getItem('token');
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    if (isLoginUser == true) {
      let sendData = {
        productId: product._id
      }
      let api = ApiNames.user_WishList
      try {

        const response = await Axios.post(`${api}`, sendData);
        setonDataReturn(response.data.message)
        addToCart(response.data.message)

      } catch (error) {
        console.error('Error:', error);
      }
    }
    else {
      // alert('user not logdin')
      // whenLoginRefreshProduct('data')
      document.dispatchEvent(new Event('customEvent'));
    }

  }


  async function getListByCategory() {

    let token = await localStorage.getItem('token');
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    if (isLoginUser == true) {

      try {

        const response = await Axios.get(`${process.env.REACT_APP_API_KEY}getCategoryWiseProductsList?id=${props._id}`);
        // setonDataReturn(response.data.message)
        // addToCart(response.data.message)
      } catch (error) {
        console.error('Error:', error);
      }
    }
    else {
      // alert('user not logdin')
      // whenLoginRefreshProduct('data')
      document.dispatchEvent(new Event('customEvent'));
    }

  }

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {


        let token = await localStorage.getItem('token');
        if (token) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        // Handle the error as needed
      }
    };

    checkLoginStatus();
  }, []);

  function addToCarts(product) {
    debugger
    let splitName = product.title.replace(/\s+/g, "-");
    navigate(`/ProductView/${splitName}/${product._id}`)
  }

  function openNewTab(product) {
    debugger
    let splitName = product.title.replace(/\s+/g, "-");
    window.open(`/ProductView/${splitName}/${product._id}`, '_blank');
  }

  async function addToCartsProduct(id, type) {
    try {
      let token = await localStorage.getItem('token');
      if (token) {
        let api = ApiNames.addToCart;
        let body = {
          productId: id,
          type: type
        };
        const response = await Axios.post(
          `${api}`, body
        );
        addToCart(response.data.message)
        Toaster(response.data.message)
        setTimeout(() => {
          document.dispatchEvent(new Event('customSideEvent'));
        }, 100)
      } else {
        document.dispatchEvent(new Event('customEvent'));
      }

    } catch (error) {

      Toaster(error?.responce?.data?.error)
      console.log(error);
    }
  }

  function fixtheNuber(data) {
    if (data !== undefined && data !== null) {
      const numericData = parseFloat(data); // Convert input string to a number
      if (!isNaN(numericData)) { // Check if the conversion was successful
        const roundedValue = Math.round(numericData); // Round to nearest integer
        return roundedValue; // Format to two decimal places
      }
    }
    return ''; // Or any other default value or behavior you prefer
  }

  return (
    <>
      <div className="row row-cols-2  row-cols-md-5 g-3">
        {props.productObj.map((products, index) => (
          <div key={index} className="col">
            <div className="card product-card">
              <div className="card-top">
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/ProductView/${products?.title.replace(/[\s/]+/g, "-")}/${products?._id}`}
                  className="productImg-top">
                  {products.quantity > 0 ? (
                    <div>
                      <img onClick={(e) => {
                        if (e.ctrlKey) {
                          openNewTab(products)
                        } else {
                          addToCarts(products);
                        }
                      }}
                        src={products.thumbnail || <MySkeleton count={1} height={130} width={'100%'} />}
                        className="card-img-top product-cardImage"
                        alt="..."
                      />


                    </div>

                  ) : (
                    <div>
                      <p className="productSoldOut">
                        <label className="lableStock">Out of Stock</label>
                      </p>
                      <img onClick={(e) => {
                        if (e.ctrlKey) {
                          openNewTab(products)
                        } else {
                          addToCarts(products);
                        }
                      }}
                        src={products.thumbnail}
                        className="card-img-top product-cardImage soldProducts"
                        alt="..."
                      />
                    </div>

                  )}

              </Link>
              {/* <div className="DiscoutBox">  
                  <div>
                    <span className="dicsoutnNu"> {products.discount}% <br />OFF</span>
                  </div>
                </div> */}
              <div>
                {
                  products.isWishlist ? (
                    <img onClick={() => (addOrRemoverWishList(products))}
                      src="/images/LandingPg/wishlist-fill.png"
                      alt=".."
                      className="wishlist-icon"
                    />
                  ) : (
                    <img onClick={() => (addOrRemoverWishList(products))}
                      src="/images/LandingPg/wishlist-icon.png"
                      alt=".."
                      className="wishlist-icon"
                    />
                  )
                }


              </div>
            </div>
            <Link

              style={{ textDecoration: 'none' }}
              to={`/ProductView/${products?.title.replace(/[\s/]+/g, "-")}/${products?._id}`}

              className="card-body product-body">
              <p className="card-title item-title">{products.title || <MySkeleton count={1} width={'100%'} />}</p>
              <div className="item-review">
                <div className="starss">
                  {products?.ReviewRating >= 1 ? <span>&#9733;</span> : <span>&#9734;</span>}
                  {products?.ReviewRating >= 2 ? <span>&#9733;</span> : <span>&#9734;</span>}
                  {products?.ReviewRating >= 3 ? <span>&#9733;</span> : <span>&#9734;</span>}
                  {products?.ReviewRating >= 4 ? <span>&#9733;</span> : <span>&#9734;</span>}
                  {products?.ReviewRating >= 5 ? <span>&#9733;</span> : <span>&#9734;</span>}
                </div>
                <span className="custemer-rivew"> {products?.totalReviews}</span>
              </div>
              <p className="item-price">
                AED <span>{fixtheNuber(products.sellingPrice)}</span>
              </p>
              {products.quantity > 0 ? (
                <button onClick={() => addToCartsProduct(products._id, 1)} className="btn add-cart">
                  <img
                    src="/images/LandingPg/bagIcn.svg"
                    alt=".."
                    className="bag-icon"
                  />
                  Add To Cart
                </button>
              ) : (
                <button className="btn add-cart">

                  Notify me
                </button>
              )}


            </Link>
          </div>
          </div>
        ))}

    </div >
    </>
  );
};

export default ProductItemsNew;
