
import Text2Title from "../Title/Text2Title";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import MySkeleton from "../Loaders/MySkeleton";

const ShpMoreCard = () => {
  let navigation = useNavigate();
  const [getItems, setItems] = useState([]);
  const [titleTwo, setTitleTwo] = useState('');
  const [titleThree, setTitleThree] = useState('');
  useEffect(() => {
    const getMoreReason = async () => {
      try {
        let url = ApiNames.getSelectedCollection
        const response = await Axios.get(
          `${url}`
        )
        setItems(response.data);

      } catch (error) {
        console.log(error)

      }
    }
    getMoreReason();

  }, [])



  useEffect(() => {
    const getMoreReason = async () => {
      try {
        let api = ApiNames.getTitles
        const response = await Axios.get(
          `${api}`
        );
        response.data.forEach((element) => {
          if (element.index === '2') {
            setTitleTwo(element.title)
          }
          if (element.index === '3') {
            setTitleThree(element.title)
          }
        });

      } catch (error) {
        console.log(error);
      }
    };
    getMoreReason();
  }, []);
  return (
    <>
      {getItems.length > 0 ? (
        <div className="ShopMore-cards">
          <Text2Title text={titleTwo} />
          <div className="row g-3">
            {getItems.map((item, index) => (
              <Link
                style={{ textDecoration: 'none', color: '#000' }}
                to={`/Filter/${item?.firstCollectionDetail?.name?.replace(/\s+/g, '-')}/4/${item?.firstCollectionDetail?._id}`}
                key={index}
                className="col-md-6"
              >                <div className="shopMore-top">
                  <div className="Shop_div">
                    {item?.firstCollectionDetail?.name ? (
                      <h3 className="Shop_txt">{item?.firstCollectionDetail?.name}</h3>
                    ) : null}

                  </div>
                  <img
                    src={item?.firstCollectionDetail?.image}
                    alt="..."
                    className="CardBanner-insideImge"
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (

        <MySkeleton count={1} height={300} width={500} />
      )}

    </>
  );
};

export default ShpMoreCard;
