import React, { useEffect } from 'react'

const Warrenty = () => {
  useEffect(() => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}, []);
  return (
    <div className='warranty-policy-container' >
      <div className="container  warranty-content">
        <h1 className="text-center warranty-title">Warranty Policy</h1>

        {/* Section A: About the Product Warranty Policy */}
        <section className="warranty-section">
          <h2 className="warranty-heading">A. About the Product Warranty Policy:</h2>
          <ol className="warranty-list">
            <li className="warranty-text">Flipdeals' warranty policy helps you stay covered in case of manufacturing defects in material, design, or workmanship after purchasing the product.</li>
            <li className="warranty-text">The duration of the warranty for the products or the accessories will be as per the manufacturer or seller.</li>
            <li className="warranty-text">Warranty services provided by sellers or brands other than Flipdeals are the responsibility of the manufacturer or seller. This includes spare parts availability, repair time, and the quality of repairs. Customers need to contact the brand's warranty service provider directly in case of complaints or other concerns. If the brand warranty service center is unavailable, Flipdeals will assist customers with their claims.</li>
            <li className="warranty-text">The original invoice is required to verify the serial number and validate the warranty period. If the device was delivered with a warranty card, customers must provide it to service centers to proceed with the claim.</li>
            <li className="warranty-text">A repair or replacement under warranty does not extend or renew the warranty period. Warranty terms are in accordance with your device manufacturer. In case of delays in resolving warranty claims, customers can claim compensation directly from service providers as per government laws. Flipdeals is not liable for this compensation.</li>
            <li className="warranty-text">Authorized service centers will carry out warranty repairs. In instances where products are bought through a seller, the respective seller's warranty terms will apply.</li>
            <li className="warranty-text">The lifecycle of devices may differ, and customers can review the device manual or contact the manufacturer for more details.</li>
          </ol>
        </section>


        {/* Section A.1: Warranty Service Policy in Case of Pickup and Delivery */}
        <section className="warranty-section">
          <h2 className="warranty-heading">A.1. Warranty Service Policy in Case the Pickup and Delivery is Required:</h2>
          <p className="warranty-text">If Flipdeals provides pickup and drop-off services (within serviceable areas) as an additional benefit, the warranty repair period would be 25 working days, broken down as follows:</p>

          <p className="boxtext">Item picked to item delivered to service center/seller.</p>
          <p className="boxtext">Repair/servicing time for seller/service center.</p>
          <p className="boxtext">Post-repair delivery from service center/seller to customer.</p>
          <p className="warranty-text mt-2"><strong>Note:</strong> Pickup and delivery services provided by Flipdeals are additional services, and Flipdeals reserves the right to charge customers for this service. The service is offered at Flipdeals' discretion, and Flipdeals also reserves the right to discontinue it.</p>
        </section>

        {/* Guidelines for Pickup and Delivery Service */}
        <section className="warranty-section">
          <h2 className="warranty-heading">Guidelines for the Warranty Pickup and Delivery Service:</h2>
          <ol className="warranty-list">
            <li className="warranty-text">Customers must ensure the item is packed in its original box and wrapped safely. Flipdeals is not responsible for any damages during transit if packaging is inadequate.</li>
            <li className="warranty-text">Customers must provide the product and its accessories to the courier company during pickup (including, but not limited to, earphones, battery, and charger).</li>
            <li className="warranty-text">The address provided during the warranty claim request will be used for both pickup and delivery. For any changes to this address, please contact Flipdeals customer care. Changes will be subject to the availability of courier partners.</li>
            <li className="warranty-text">If an item cannot be repaired but is still under the manufacturer's warranty, customers will be eligible for a replacement or a refund. Replacement and refund decisions are made by the brand service provider or seller and must be claimed directly from them.</li>
            <li className="warranty-text">Some service centers may charge a fee for inspection if the warranty claim is rejected and the item is out of warranty.</li>
            <li className="warranty-text">Warranty does not cover damages caused by accidents, abuse, misuse, liquid contact, or other external causes. Such cases are considered out of warranty.</li>
            <li className="warranty-text">If a customer receives a damaged item or an item with missing accessories after servicing, this must be reported within 48 hours of delivery. No claims will be entertained after this period.</li>
            <li className="warranty-text">Customers are responsible for any personal data on the device. Customers are advised to back up their data, switch off "Find My Device," and remove any locks/passwords before handing over the device for servicing.</li>
            <li className="warranty-text">The time taken to obtain device information or customer approval is not included in the turnaround time (TAT) for repairs.</li>
            <li className="warranty-text">By submitting an item for service, customers consent to the use of their contact details by Flipdeals and the service provider to fulfill the service requirements.</li>
            <li className="warranty-text">Customers confirm that all information provided during the warranty claim is correct.</li>
            <li className="warranty-text">Any extra accessories (e.g., SIM cards, screen protectors, covers, electronic pens) provided with the device are the customer's responsibility. Customers should remove these items before handing over the device. Flipdeals will not be liable for any damage or loss of these accessories.</li>
            <li className="warranty-text">If a customer refuses to receive the product after processing the warranty claim, the product will be stored in Flipdeals' warehouse for a maximum of 30 calendar days. After this period, Flipdeals will dispose of the product. Once disposed of, the customer will no longer be able to request delivery, and no compensation will be provided (including the item's value).</li>
            <li className="warranty-text">The contact information registered with the account cannot be changed during a warranty claim and will be used by Flipdeals and brand service centers for communication.</li>
          </ol>
        </section>


        {/* Section A.2: Warranty Provided by Brands or Sellers */}
        <section className="warranty-section">
          <h2 className="warranty-heading">A.2. Warranty Provided by Brands or Sellers:</h2>
          <ol className="warranty-list">
            <li className="warranty-text">The device warranty is provided by the manufacturer or their authorized service provider and is subject to the manufacturer's warranty policies, which can be found in the user manual or on the manufacturer's official website. To request warranty services, customers must contact manufacturers or their authorized service centers directly, as per the rules and regulations. The list of service providers can be accessed through this link: <a className="warranty-link" href="https://warranty.noon.com/uae-en/">Service Center link</a>.</li>
            <li className="warranty-text">Warranty services provided by brand service centers or sellers are not the responsibility of Flipdeals. This includes spare part availability, repair time, and the quality of repairs. Customers must contact the warranty service provider directly for complaints or other concerns.</li>
            <li className="warranty-text">Flipdeals provides its own warranty on some devices to ensure the highest quality of service. Customers can contact us for more information.</li>
          </ol>
        </section>


        {/* Section B: Minute's Warranty Policy */}
        <section className="warranty-section">
          <h2 className="warranty-heading">B. Minute's Warranty Policy:</h2>
          <p className="warranty-text">The products listed under "Flipdeals in Minutes" section on the Flipdeals platform are provided to the customer by third-party sellers. Hence, the following table will be applicable:</p>
          <div className="warranty-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Seller</th>
                  <th>Warranty Duration</th>
                  <th>Categories</th>
                  <th>Number of Claims per Issue Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Third-party Seller</td>
                  <td>As per seller warranty terms.</td>
                  <td>Against whom seller warranty is being provided and mentioned on the product page.</td>
                  <td>As per seller warranty terms.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        {/* Contact Information */}
        <p className="warranty-contact">Replacements and repaired products or parts will only be shipped to addresses within the
          purchase territory, and refunds will only be credited to accounts located within the purchase
          territory.</p>
        <p className="warranty-contact">For any further clarification, please reach out to our customer service.
          You may contact us through email <a className="warranty-link" href="mailto:support@flipdeals.com">support@flipdeals.com</a>or live chat through our mobile
          application.</p>
      </div>
    </div>
  )
}

export default Warrenty