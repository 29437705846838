import React, { useState, useEffect } from 'react';
import Header from '../../Component/Header/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../../Component/Footer/Footer';

const Layout = () => {
    const [isOffline, setIsOffline] = useState(!navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOffline(false);
        const handleOffline = () => setIsOffline(true);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    useEffect(()=>{
        document.addEventListener('customServerIssueEvent', NetworkError);
    },[])


    const NetworkError = () =>{
        setIsOffline(true);
     
    }

    return (
        <>
        <Header />
        <div className='headerAndBody'>
            <Outlet />
        </div>
        <Footer />
    </>
    );
};

export default Layout;
