import React, { memo, useEffect, useRef, useState } from "react";
import Axios from "../../utils/AxiosConfi";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ApiNames from "../../Constants/ApiUrls";
import { useNavigate } from "react-router-dom";

const Searchbar = ({ className }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(true);
    const navigate = useNavigate();
    const [searchName, setSearchName] = useState("");
    const [getselectedCategory, setselectedCategory] = useState("All Categories");
    const [getselectedCategoryId, setselectedCategoryId] = useState("");
    const [categories, setCategories] = useState([]);
    const [getListSearch, setListSearch] = useState([]);
    const [getOriginalData, setOriginalData] = useState([]);
    const [getSearchItems, setSearchItems] = useState([]);
    const [opacity, setOpacity] = useState(0);
    const [getCategory, setCategory] = useState([]);
    const [searchResult, setSearchResults] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');

    //   const { addToCart } = useCart();
    function addToCarts(id) {
        setShowCart(false);
        navigate(`/ProductView/${id}`);
    }
    const handleSearch = async (event) => {
        try {
            let query = event.target.value;

            let id = getselectedCategoryId;
            let sendObj = {
                q: query,
                id: getselectedCategoryId
            }
            setSearchName(query);
            let response = await Axios.post(`${ApiNames.searchItems}`, sendObj);
            setSearchItems(response.data);
            if (response.data.length > 0) {
                setOpacity(1);
            }
            setSearchResults(true);
        } catch (err) {
            setSearchName("");
            setOpacity(0);
            setSearchResults(false);
        }
    };

    const hideDropdown = (state) => {
        const dropdown = document.querySelector('.rbt-menu'); // Target the element by class

        if (dropdown && state === true) {
            if (dropdown) {
                dropdown.style.display = ''; // Hide the dropdown
            }
        } else {
            if (dropdown && dropdown) {
                dropdown.style.display = 'none'; // Hide the dropdown
            }
        }

    };

    const handleKeyUp = async (query) => {
        console.log(query)
        if(!query){
            return
        }
        setInputText(query);
        
        if (query.length > 0) {
            try {
                sessionStorage.setItem('pageIndex',1)
                const response = await Axios.get(`${ApiNames.searchProducts}${query}`);
                setOriginalData(response.data)
                let searchData = [];
                debugger


                if (response.data.length <= 0) {
                    hideNoItemsFound()

                }
                response.data.forEach((value) => {
                    if (value.name) {
                        searchData.push(value.name);
                    }

                });


                setListSearch(searchData);
                // setShowDropdown(true); 
            } catch (err) {
                console.error(err);
            }
        } else {
            setShowDropdown(false); // Hide dropdown if query is empty
        }
    };

    const handleSearchByClick = async () => {
    
        gotoFilterPage(inputText)
    };
    useEffect(() => {
        const handleSearch = async () => {
            try {

                const response = await Axios.get(`${ApiNames.getAllCategory}`);
                setCategory(response.data);
                setCategories(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        handleSearch();
    }, []);




    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredCategories = categories.filter(category =>
        category.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    function selectedCategory(data) {
        setselectedCategory(data.categoryName)
        setselectedCategoryId(data._id)
    }

    const handleSelect = (selected) => {
        if (selected[0] && selected[0].customOption === true) {
            selected[0] = selected[0]?.label
        }
        setInputText(selected[0]);
        if (selected[0] !== undefined) {
            gotoFilterPage(selected[0])
        } else {
            hideNoItemsFound()
        }
    };
    const typeaheadRef = useRef(null);
    const handleKeyPress = (event) => {
        debugger
        if(!inputText){
            return
        }
        if (event.key === 'Enter') {
            const inputValue = typeaheadRef.current?.getInput().value;
            console.log('Enter pressed with value:', inputValue);
            setInputText(inputValue)
            if (inputValue !== undefined) {
                gotoFilterPage(inputValue)
            } else {
                hideNoItemsFound()
            }

        }
    };

    function hideNoItemsFound() {
        // var text = "No mathing products";
        // var carTypeahead = document.getElementById('car-typeahead');
        // if (carTypeahead) {
        //     var innerText = carTypeahead.innerText || carTypeahead.textContent; // Get the text content inside the div

        //     // Check if the inner text contains the specified text
        //     if (innerText.includes(text)) {
        //         carTypeahead.style.display = 'none'; // Hide the div if the text is found
        //     } else {
        //         carTypeahead.style.display = 'block'; // Show the div if the text is not found
        //     }
        // }
    }

    function gotoFilterPage(query) {
        hideDropdown(false)
        // query = query.replace(/[^a-zA-Z0-9-]/g, '');
        if(!query){
            return
        }
        navigate(`/Filter/Search/7/${encodeURIComponent(query)}`);


        return
        let navigated = false; // Flag to track if navigation has occurred

        const found = getOriginalData.some((value) => {

            if (value.name === query) {
                if (value.type === 'category') {
                    navigate(`/Filter/Search/1/${value._id}`);
                    return true; // Found and navigated
                }
                if (value.type === 'subCategory') {
                    navigate(`/Filter/Search/6/${value._id}`);
                    return true; // Found and navigated
                }
                if (value.type === 'tag') {
                    query = query.replace(/\s+/g, '-');

                    // Step 2: Remove special characters (except dashes and alphanumeric characters)
                    query = query.replace(/[^a-zA-Z0-9-]/g, '');
                    navigate(`/Filter/Search/7/${query}`);
                    return true; // Found and navigated
                }
            }
            return false; // Not found or not navigated
        });

        if (!found) {
            // query = query.replace(/\s+/g, '-');

            // Step 2: Remove special characters (except dashes and alphanumeric characters)
            query = query.replace(/^[^a-zA-Z0-9]+/, '');
            // If no match was found in getOriginalData, navigate to /Filter/7/{query}
            navigate(`/Filter/Search/7/${query}`);
        }
    }

    const handleClear = () => {
        // setInputValue(''); // Clear the input value
        // setShowDropdown(false); // Hide the dropdown if needed
        typeaheadRef.current.clear(); // Optionally reset the Typeahead
    };


    useEffect(() => {
        // Listen for the custom event when the component mounts
        document.addEventListener('searchClear', handleClear);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('searchClear', handleClear);
        };
    }, []);




    return (
        <>
            <div className={`search-container ${className}`} >
                <div className=" serachbar-form">
                    {/* <div className="dropdown select-wrapper">
                        <button
                            className="btn  dropdown-toggle custom-select"
                            type="button"
                            id="dropdownMenuButton3"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {getselectedCategory}
                            <img
                                className="down-arrow"
                                src="/images/iconsImages/downarw.svg"
                            />
                        </button>
                        <ul
                            className="dropdown-menu category-dropdwn-box"
                            aria-labelledby="dropdownMenuButton3"
                        >
                            <div className="category-data-box">
                                <input
                                    className="category-searchInput"
                                    type="text"
                                    id="category_search_text"
                                    placeholder="Search for items"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                                <div className="category-lists-top">
                                    {filteredCategories.map((value, index) => (
                                        <li key={index} className="category-lists" onClick={() => selectedCategory(value)}>
                                            <a className="list-items-inside">{value?.categoryName}</a>
                                        </li>
                                    ))}


                                </div>
                            </div>
                        </ul>
                    </div> */}
                    <div className=" search-Drpdown">
                        <form
                        >
                            {/* <input
                                onKeyUp={handleSearch}
                                type="text"
                                className="searchInput"
                                id="header_search_text"
                                placeholder="What are you shopping for?"
                                aria-label="Search"
                            /> */}

                            <Typeahead
                                allowNew
                                id="car-typeahead"
                                options={getListSearch}
                                placeholder="What are you shopping for?"
                                minLength={1}
                                emptyLabel="No matching products"
                                maxResults={30}
                                isLoading={isLoading}
                                paginate={false}
                                onChange={handleSelect}
                                highlightOnlyResult={false}
                                onInputChange={(text) => handleKeyUp(text)}
                                inputValue={inputValue}
                                className="typeahead searchInput"
                                isMenuShown={getListSearch.length > 0 && showDropdown} // Only show dropdown if there are results
                                ref={typeaheadRef}
                                onKeyDown={handleKeyPress}
                            />



                            <span onClick={handleSearchByClick} className="search-btn">
                                <img
                                    src="/images/menubar/search.svg"
                                    className="serch-image"
                                    alt=""
                                />
                            </span>
                        </form>


                    </div>
                </div>
            </div>

        </>
    );
};

export default memo(Searchbar);















