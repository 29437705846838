import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ReactImageMagnify from 'react-image-magnify';
// Import Swiper styles
import ReactPlayer from 'react-player'
import "swiper/css";
import { useEffect, useRef, useState } from "react";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import useRouteFunction from "./../../utils/route";
import { useNavigate, useParams } from "react-router-dom";

import { useCart } from "../../Context/CartContext";
import SimilerProducts from "../../Component/SimilerProducts/SimilerProducts";
import StarRating from "../StarRating";
import Pbrands from "../popularBrands/popularBrands";
import Welcome from "../../Component/Loaders/Welcome";
import Toaster from "../../utils/Toaster";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";

const ProductView = () => {
  const routeFunction = useRouteFunction();
  const [hoverText, setHoverText] = useState("");
  const [getHelmetData, setHelmetData] = useState(null);
  const [getMaxItems, setMaxItems] = useState(0);
  const [getVariantsList, setVariantsList] = useState([]);
  const [getNewDeliveryTypes, setDeliveryTypes] = useState([]);

  const [initialSelectedValues, setInitialSelectedValues] = useState({});

  const handleMouseEnter = (key, value) => {
    setHoverText(`${key}: ${value}`);
  };

  const handleMouseLeave = () => {
    setHoverText("");
  };

  useEffect(() => {
    const initialValues = {};
    getVariantsList.forEach(value => {
      const selectedVariant = value.variants.find(item => item.status);
      if (selectedVariant) {
        initialValues[value.key] = selectedVariant.value;
      }
    });
    setInitialSelectedValues(initialValues);
    getMaxLimit()
  }, [getVariantsList]);


  function fixtheNuber(data) {
    if (data !== undefined && data !== null) {
      const numericData = parseFloat(data); // Convert input string to a number
      if (!isNaN(numericData)) { // Check if the conversion was successful
        const roundedValue = Math.round(numericData); // Round to nearest integer
        return roundedValue; // Format to two decimal places
      }
    }
    return ''; // Or any other default value or behavior you prefer
  }
  const playerRef = useRef(null);
  const navigate = useNavigate();
  let navigations = useNavigate();
  const { productId } = useParams()

  const [imageSrc, setImageSrc] = useState(null);
  const [getFinalVideoUrl, setFinalVideoUrl] = useState('');
  const [getItemCount, setItemCount] = useState(1);
  const [getDec, setDec] = useState([]);

  const [getPramotional, setPramotioalImages] = useState([]);
  const [getattributes, setattributes] = useState([]);
  const [getOtherProducts, setOtherProducts] = useState([]);
  const [getproductImages, setproductImages] = useState([]);
  const [getTopVar, setTopVar] = useState([]);
  const [getIsLoader, SetIsLoader] = useState(true);
  const [getTwoCategoris, setTwoCategories] = useState([]);
  const [getReviewEligibility, setReviewEligibility] = useState(false);
  const [getWishlist, setWishlist] = useState(false);
  const { addToCart } = useCart()
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [getThumbnail, setThumbnail] = useState('');
  const [getVariantId, setVariantId] = useState('');
  const [getDeliveryMethod, setDeliveryMethod] = useState('');
  const [selectedDelivery, setSelectedDelivery] = useState([]);

  const handleSelectionChange = (value, delivery) => {
    setSelectedDelivery(value);

    console.log(delivery)
    setDeliveryMethod(delivery?.DeliveryDetails?._id)

  };
  const [selectedImage, setSelectedImage] = useState(
    "/images/LandingPg/banner1.png"
  );

  const [getProduct, setProduct] = useState(
    "/images/LandingPg/banner1.png"
  );
  const [getImage, setImage] = useState('');
  useEffect(() => {
    if (!productId) {
      return
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [productId]);

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime(), 'seconds');
      playerRef.current.getInternalPlayer().play();
    }
  };

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause();
    }
  };



  const createHelmetSchema = async (product) => {

    debugger
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": product?.title,
      "image": product?.thumbnail,
      "description": product?.description,
      "sku": product?.skuNumber,
      "brand": {
        "@type": "Brand",
        "name": product?.brandId
      },
      "offers": {
        "@type": "Offer",
        "url": `https://flipdeals.com/ProductView/${product.title}/${product._id}`,
        "priceCurrency": 'USD',
        "price": product.sellingPrice,
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": 'Flipdeals'
        }
      }
    };
    document.Head = schemaData
    setHelmetData(schemaData)
  }

  // useEffect(() => {
  //   const getImages = async () => {
  //     try {
  //       // Assuming ApiNames is an object with keys for different API endpoints
  //       const apiw = ApiNames.getPramotionalImagesByProductId;

  //       // Assuming the API URL is correctly formed using environment variables
  //       const response = await Axios.get(
  //         `${apiw}${productId}`
  //       );

  //       // Assuming pramotionalImages is a typo, corrected to promotionalImages
  //       console.log("promotionalImages", response.data);
  //     } catch (error) {
  //       console.error('Error fetching promotional images:', error);
  //     }
  //   };

  //   getImages();
  // }, []);

  const checkLoginStatus = async () => {
    try {

      let api = ApiNames.getProductById
      const response = await Axios.get(
        `${api}${productId}`
      );

      if (response.data.productDetails.status === 'inactive') {
        navigations(`/landing`)
        return
      }
      createHelmetSchema(response.data.productDetails)
      debugger
      setProduct(response.data.productDetails)
      setWishlist(response.data?.isWishlist)
      setVariantId(response.data?.productDetails?.variantId)
      setattributes(response.data?.productDetails?.attributes)
      getProductsByCategory(response.data.productDetails?.categoryId)
      setPramotioalImages(response.data.pramotion)
      setproductImages(response.data.productImages)
      SetIsLoader(false)
      setDec(response?.data?.productDetails?.descriptionList)
      let thumb = {
        image: response?.data?.productDetails.thumbnail,
        type: 'image'
      }
      setThumbnail(thumb)
      getTwoCategories()
      localStorage.setItem('similar', JSON.stringify(
        {
          slug: response.data.productDetails.slug,
          categoryId: response.data.productDetails.categoryId,
          _id: response.data.productDetails._id
        }))

    } catch (error) {
      console.error("Error checking login status:", error);
    }
  };
  useEffect(() => {


    checkLoginStatus();
    getDeliveryTypes()
  }, [productId]);


  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        let selectedForName = []
        setSelectedVariants([])
        let api = ApiNames.getVariants
        const response = await Axios.get(
          `${api}${productId}`
        );

        let itemsInfo = []
        response.data.forEach((info) => {
          if (info && info.variants) {
            info.variants.forEach((value) => {
              if (value.status === true) {
                const items = {
                  key: info.key,
                  val: value.value
                };
                itemsInfo.push(items)

              }
            });
          }
        });
        setTopVar(itemsInfo)
        console.log(itemsInfo)
        setVariantsList(response.data)
        let data = response.data

        // setSelectedVariants(response.data)

        let objects = []
        response.data.forEach(category => {
          category.variants.forEach(variant => {
            if (variant.status) {
              objects.push({ name: category.key, value: variant.value })
            }
          });
        });
        setSelectedVariants(objects)
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    checkLoginStatus();
  }, [productId]);





  async function getVariantiWide() {
    try {

      let api = ApiNames.matchVariant
      const response = await Axios.post(
        `${api}`
      );

      console.log("response", response)
      setVariantsList(response.data)

    } catch (error) {
      console.error("Error checking login status:", error);
    }
  }


  const getUpdatedVariant = async (dataObjects) => {
    try {
      debugger
      let query = {
        variantId: getVariantId,
        attributes: dataObjects
      }
      let api = ApiNames.matchVariant
      const response = await Axios.post(
        `${api}`, query
      );
      console.log("responseresponseresponse", response)
      let splitName = response?.data?.title.replace(/\s+/g, "-");
      navigate(`/ProductView/${encodeURIComponent(splitName)}/${response?.data?._id}`);

      // navigations(`/ProductView/${response?.data?._id}`)
    } catch (error) {
      console.error("Error checking login status:", error);
    }
  };  


  function selectedVariant(variant) {

    console.log(variant)

    const existingVariantIndex = selectedVariants.findIndex(v => v.name === variant.name);
    let updatedVariants;
    if (existingVariantIndex !== -1) {
      // Variant with the same name already exists, replace it
      setSelectedVariants(prevVariants => [
        ...prevVariants.slice(0, existingVariantIndex),
        variant,
        ...prevVariants.slice(existingVariantIndex + 1)
      ]);


      updatedVariants = [
        ...selectedVariants.slice(0, existingVariantIndex),
        variant,
        ...selectedVariants.slice(existingVariantIndex + 1)
      ];
    } else {
      // Variant does not exist, add it
      setSelectedVariants(prevVariants => [...prevVariants, variant]);
      updatedVariants = []
      // updatedVariants = [...selectedVariants, variant];
      updatedVariants = [...selectedVariants, variant];
    }


    console.clear()
    console.log(updatedVariants)
    getUpdatedVariant(updatedVariants)
  }

  async function getProductsByCategory(categoryId) {

    let productItems = []
    let api = ApiNames.getCategoryWiseProductsList
    const response = await Axios.get(`${api}${categoryId}`);

    response.data.products.forEach((value) => {
      if (value._id !== productId) {
        productItems.push(value)
      }
    })
    setOtherProducts(productItems)
  }

  async function getDeliveryTypes() {
    let api = ApiNames.getDeliveryTypes
    const response = await Axios.get(`${api}${productId}`);
    setSelectedDelivery(response?.data?.response[0]?.deliveryName)
    handleSelectionChange(response?.data?.response[0]?.deliveryName, response?.data?.response[0])
    setDeliveryTypes(response?.data?.response)
  }




  async function getTwoCategories() {
    let api = ApiNames.getShowCategory

    try {
      const response = await Axios.get(`${api}`);

      setTwoCategories(response.data.slice(0, 2))
    } catch (err) {
      console.log(err)
    }
  }


  async function reviewEligible() {
    try {
      let api = ApiNames.reviewEligible
      const response = await Axios.get(
        `${api}${productId}`
      );

      setReviewEligibility(response.data.status)
    } catch (err) {
      console.log(err)
    }
  }

  const increment = () => {

    if (getItemCount < getProduct.quantity && getItemCount < getMaxItems.itemcount) {
      setItemCount(prevNumber => prevNumber + 1);
    }
  };

  const decrement = () => {
    if (getItemCount > 1) {
      setItemCount(prevNumber => prevNumber - 1);
    }
  };

  const handleCarouselImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  // review logics////
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [review, setReview] = useState('');
  const [file, setFile] = useState(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };

  const handleFileChange = async (e) => {
    let file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('images', file);
      let api = ApiNames.uploadImage;

      try {
        let token = await localStorage.getItem('token');

        const response = await Axios.post(
          `${api}`,
          formData, // Send formData directly as the request body
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Set content type for formData
              'Authorization': `Bearer ${token}` // Add 'Bearer' prefix for the token
            }
          }
        );

        setImage(response.data.imagePath)

      } catch (error) {
        toast(error.response.data.error);
        console.error('Error uploading image:', error);
      }
    } else {
      console.warn('No file selected for upload.');
    }
  };

  const notify = (data) => toast(data);

  function getProductInfo(data) {
    navigations(`/ProductView/${data?._id}`)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    let stars = localStorage.getItem('stars')

    let saveObj = {
      productId: productId,
      name: name,
      description: review,
      image: getImage,
      starRating: parseInt(stars)

    }
    try {
      let api = ApiNames.reviewToProduct;
      const response = await Axios.post(`${api}`, saveObj);
      setShow(false)
      checkLoginStatus();
      // try {

      //   let api = ApiNames.getProductById
      //   const response = await Axios.get(
      //     `${api}${productId}`
      //   );
      //     
      //   setProduct(response.data)


      // } catch (error) {
      //     
      //   console.error("Error checking login status:", error);
      //   Toaster(error.error.error)
      // }
      setName('');
      setEmail('');
      setReview('');
      setFile(null);

      Toaster(response?.data?.message)


    } catch (err) {

      Toaster(err?.response?.data?.error)
      console.log(err)
    }





    // Reset the form values (optional)

  };
  async function getMaxLimit() {
    let api = ApiNames.getMaxLimit
    const response = await Axios.get(`${api}`);
    setMaxItems(response.data?.responce)

  }

  async function addToCartsProduct() {
    try {
      let token = await localStorage.getItem('token');
      if (!token) {
        document.dispatchEvent(new Event('customEvent'));
        return
      }
      let api = ApiNames.addToCart;
      let body = {
        productId: productId,
        type: 1,
        quantity: getItemCount,
        DeliveryType: getDeliveryMethod
      };
      const response = await Axios.post(
        `${api}`, body
      );

      console.log(response);
      addToCart(response.data.message)
      document.dispatchEvent(new Event('customSideEvent'));
    } catch (error) {
      console.log(error);
      toast(error.response.data.error);
    }
  }


  async function directPurchese() {
    try {
      let token = await localStorage.getItem('token');
      if (!token) {
        document.dispatchEvent(new Event('customEvent'));
        return
      }
      let api = ApiNames.addToCart;
      let body = {
        productId: productId,
        type: 1,
        quantity: getItemCount,
        DeliveryType: getDeliveryMethod
      };
      const response = await Axios.post(
        `${api}`, body
      );
      navigations(`/CartItems`)
      addToCart(response?.data?.message)

    } catch (error) {
      console.log(error);
      toast(error.response.data.error);
    }
  }

  async function handleCarouselImageClickFUn(data) {
    setThumbnail(data)

  }
  const [itemCount, setItemCounts] = useState('');

  const handleItemCountChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) { // Check if the entered value is a valid number
      setItemCount(newValue);
    }
  };

  function setVideoURL(url) {

    // Check if the URL ends with .mp4
    let finalURL = url.includes('.mp4') ? url.slice(0, -4) : url;
    // Replace /images/ with /videos/ and reassign to finalURL
    finalURL = finalURL.replace('/images/', '/videos/');
    console.log(finalURL);
    // Call setFinalVideoUrl with the modified URL
    setFinalVideoUrl(finalURL);
  }
  function handleItemClick(productId) {
    navigate(`/Filter/1/${productId}`)
  };
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const percentageScrolled = (scrollPosition / (documentHeight - windowHeight)) * 100;
      if (percentageScrolled > 22) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleWish = async (val) => {
    try {
      const token = await localStorage.getItem('token');
  
      if (!token) {
        document.dispatchEvent(new Event('customEvent'));
        return;
      }
  
      const api = ApiNames.user_WishList;
      const sendData = { productId: getProduct._id };
  
      const { data } = await Axios.post(api, sendData);
  
      console.log(data);
  
      if (data.type === 'removed') setWishlist(false);
      else if (data.type === 'added') setWishlist(true);
  
      Toaster(data?.message);
      addToCart(data.message);
    } catch (error) {
      console.error('Error while handling wish:', error);
    } finally {
      console.log('triggers', val);
    }
  };
  
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(getHelmetData)}</script>
      </Helmet>
      <div >
        {getIsLoader === true ? (
          <div>
            <Welcome />
          </div>
        ) : (
          <div>
            <div className={`TopSectionOfProduct ${visible ? 'visible' : 'hidden'}`}>
              <div className="topButtons">
                <div className="imgFGro">
                  <img className="imgeICon" src={getThumbnail.image} alt={getProduct.title} />
                  <div >
                    <label className="lablesName">    {getProduct.title.slice(0, 140)}</label>
                    <div className="titleInts">
                      {getTopVar.map((val, index) => (
                        <div key={index}>
                          <label className="lablesNameNew">{val.key}: {val.val}</label>
                        </div>
                      ))}
                    </div>

                  </div>

                </div>

                <div>
                  <div className="add-to-cart-buttons">
                    <button onClick={() => addToCartsProduct()} className="addcartbuttonNew">Add to Cart</button>
                    <button onClick={() => directPurchese()} className="buyitnowbuttonNew">Buy it Now</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <ToastContainer position="bottom-center" theme="dark" hideProgressBar={false} />

              <div className="about-item">

                <label className="Brodcal">
                  <span onClick={() => routeFunction('/landing')}>Home</span>
                  {getProduct?.categoryName && (
                    <>
                      <span className="productName"> / {getProduct?.categoryName}</span>
                      {getProduct?.subCategoryName && (
                        <>
                          <span className="productName"> / {getProduct?.subCategoryName}</span>
                        </>
                      )}
                    </>
                  )}
                </label>                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <div className="about-item-image">
                      <div className="top-image-item">
                        {/* <img src={getProduct.thumbnail} alt="" className="top-image-item" /> */}
                        <div style={{ borderRadius: '10px', }}>
                          <div style={{ borderRadius: '10px', }}>
                            <div style={{ borderRadius: '10px', position: 'relative' }}>
                              {getThumbnail.type === 'image' ? (
                                <>
                                  <p className="wishListIBG">
                                    {getWishlist ? (
                                      <img onClick={() => handleWish(getWishlist)} style={{ width: '66px', cursor: 'pointer' }} src="/images/LandingPg/wishlist-fill.png" alt=".." class="wishlist-icon" />

                                    ) : (
                                      <img onClick={() => handleWish(getWishlist)} style={{ width: '66px', cursor: 'pointer' }} src="/images/LandingPg/wishlist-icon.png" alt=".." class="wishlist-icon" />
                                    )}
                                  </p>
                                  <ReactImageMagnify {...{
                                    smallImage: {
                                      alt: getProduct.title,
                                      isFluidWidth: true,
                                      src: getThumbnail.image,
                                      className: 'inside-thumbimage',
                                      style: { borderRadius: '10px', overflow: 'hidden' }
                                    },
                                    largeImage: {
                                      src: getThumbnail.image,
                                      width: 1200,
                                      height: 1800,
                                      style: { borderRadius: '10px', }
                                    }
                                  }} />
                                </>
                              ) : (
                                <div onClick={() => setVideoURL(getThumbnail.image)} data-bs-toggle="modal"
                                  data-bs-target="#staticBackdropVideo" className="videoImg">
                                  <i className="fas fa-play-circle"></i>
                                  <img width={'100%'} height={'auto'} src={getThumbnail.image} alt={getProduct.title} />
                                </div>
                              )}

                            </div>

                          </div>

                        </div>

                      </div>
                    </div>

                    <div className="about-ietm-carosual">
                      <Swiper
                        className="mySwiper swiper-top"
                        //  navigation={{ clickable: true }} // Enable clickable navigation
                        navigation={true}
                        modules={[Navigation]}
                        slidesPerView={3}
                        spaceBetween={20}
                      >
                        {getproductImages.map((imageUrl, index) => (
                          <SwiperSlide key={index}>
                            <div
                              className="imageSlide-top"
                              onClick={() => handleCarouselImageClickFUn(imageUrl)}
                            >
                              {imageUrl.type === 'image' ? (
                                null
                              ) : (
                                <i style={{ position: 'absolute' }} className="fas fa-play-circle"></i>

                              )}
                              <img
                                className="imagesBottm"
                                src={imageUrl.image}
                                alt={getProduct.title}
                              />
                            </div>
                          </SwiperSlide>
                        ))}

                        {/* Add similar onClick handlers for other carousel images */}
                      </Swiper>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5">
                    <div className="about-details">
                      <p className="item-para">
                        {getProduct.title}
                      </p>
                      <div className="cost-details">


                        {getProduct?.discount !== 0 ? (
                          <div className="cost-offer">
                            <p className="marketPrice">Was : <span className="selingstrikg">{fixtheNuber(getProduct.marketPrice)}  AED </span></p>
                            <p className="marketPrice">Now : <span className="disocunt">{fixtheNuber(getProduct.sellingPrice)} AED </span></p>
                            <p className="marketPrice">Saving : {fixtheNuber(getProduct.marketPrice - getProduct.sellingPrice)}  AED <span className="Savingss">{getProduct?.discount}% Off</span></p>

                          </div>
                        ) : (
                          <div className="cost-offer">
                            <p className="marketPrice">Price : <span className="disocunt">{fixtheNuber(getProduct.sellingPrice)} AED </span></p>
                          </div>
                        )}

                      </div>

                      <p className="inclusiveline">Inclusive of VAT</p>
                      {/* <p className="termsPrice">(As Per Terms Price: {getProduct.sellingPrice})</p> */}
                      {/* <p className="notePrice">Note: Price may vary according to the payment mode.</p> */}


                      {getProduct.quantity > 0 ? (

                        <div>
                          <div className="quantity-details">
                            <p className="quantity-para">Quantity</p>
                            <div className="quantity-increase">
                              <div className="row">


                                <div onClick={decrement} className="col quantity-plus">
                                  <img className="subtraction" src="/images/LandingPg/subtractt.png" alt={getProduct.title} />

                                </div>
                                <div className="col quantity-plus">


                                  <input className="quantityENter" type="tel" onChange={handleItemCountChange} value={getItemCount} maxLength={4} />

                                </div>
                                <div onClick={increment} className="col quantity-plus">
                                  <img className="addition" src="/images/LandingPg/addplus.png" alt={getProduct.title} />

                                </div>
                              </div>
                            </div>
                          </div>

                          {getNewDeliveryTypes.length > 0 ? (
                            <div className="deliveryTypes">
                              {getNewDeliveryTypes.map((value, index) => (
                                <div className={index === getNewDeliveryTypes.length - 1 ? "deliveryLast" : "deliveryTypeOne"} key={index}>
                                  {index !== getNewDeliveryTypes.length - 1 && (
                                    <h6 className="OrContent">OR</h6>
                                  )}
                                  <div className="deliveryOne">
                                    <span className="deliveryTitle">{value?.DeliveryDetails?.name}</span>
                                  </div>
                                  <div className="deliveryTwo">
                                    <h6 className="deliveryDesctiption">
                                      {value?.DeliveryDetails?.description} <span className="descriptions">{value?.DeliveryDetails?.context}</span>
                                    </h6>
                                  </div>
                                  <div className="deliveryThree">
                                    <span className="deliveryTitle">
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={selectedDelivery === value?.deliveryName}
                                            onChange={() => handleSelectionChange(value?.deliveryName, value)}
                                          />
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}


                            </div>
                          ) : (
                            null
                          )}



                          <div className="add-to-cart-buttons">
                            <button onClick={() => addToCartsProduct()} className="addcartbutton">Add to Cart</button>
                            <button onClick={() => directPurchese()} className="buyitnowbutton">Buy it Now</button>
                          </div>

                        </div>
                      ) :
                        (
                          <div>
                            <div className="quantity-details">
                              <p className="quantity-para">Quantity</p>
                              <div className="quantity-increase">
                                <div className="row">


                                  <div className="col quantity-plus">
                                    <img className="subtraction" src="/images/LandingPg/subtractt.png" alt={getProduct.title} />

                                  </div>
                                  <div className="col quantity-plus">


                                    <input className="quantityENter" type="tel" value={getItemCount} maxLength={4} />

                                  </div>
                                  <div className="col quantity-plus">
                                    <img className="addition" src="/images/LandingPg/addplus.png" alt={getProduct.title} />

                                  </div>
                                </div>

                              </div>
                              <p className="notAvailale">Products not available</p>

                            </div>
                            <div className="add-to-cart-buttons">
                              <button className="addcartbuttonD">Add to Cart</button>
                              <button className="buyitnowbuttonD">Buy it Now</button>
                            </div>

                          </div>


                        )}
                      <div>
                        {getVariantsList.map((value, index) => (
                          <div key={index} className="productVariants">
                            <label className="variant">
                              <span className="variantTile">{`${value.key} :`}</span> {initialSelectedValues[value.key]}
                              {hoverText && hoverText.startsWith(value.key) && (
                                <span className="hoverText">{hoverText.replace(`${value.key}:`, "")}</span>
                              )}
                            </label>
                            <div className="variants">
                              {value.variants.map((items, i) => (
                                <button
                                  key={i}
                                  className={items.status ? "selecteds" : "notselecteds"}
                                  onClick={() => selectedVariant({ name: value.key, value: items.value })}

                                >
                                  {["Color", "Colors", "colors", "Colours", "COLOURS", "Colour"].includes(value.key) ? (
                                    <div>
                                      <img width={60} height={60} src={items?.thumbnails[0]} alt={items.value} />
                                    </div>
                                  ) : (
                                    items.value
                                  )}
                                </button>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="rating-stars">
                        <span className="stars-inside">
                          <span>{getProduct?.ReviewRating >= 1 ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                          <span>{getProduct?.ReviewRating >= 2 ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                          <span>{getProduct?.ReviewRating >= 3 ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                          <span>{getProduct?.ReviewRating >= 4 ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                          <span>{getProduct?.ReviewRating >= 5 ? <span>&#9733;</span> : <span>&#9734;</span>}</span>
                        </span>
                        <div onClick={reviewEligible}>
                          <p className="write-review" onClick={handleShow}>
                            Write Review
                          </p>
                        </div>


                        <Modal
                          centered
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter"
                          show={show}
                          onHide={handleClose}
                          className="review-modal-top"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="revieww-title">
                              Add Review
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="add-review">
                              {/* ... existing JSX ... */}
                              {getReviewEligibility === true ? (
                                <Form onSubmit={handleSubmit}>
                                  <p className="item-Title">
                                    {getProduct.title}
                                  </p>
                                  {/* ... existing JSX ... */}
                                  <StarRating />
                                  <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                      type="text"
                                      placeholder="Name"
                                      className="text-input"
                                      value={name}
                                      onChange={handleNameChange}
                                    />
                                  </Form.Group>
                                  {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    className="text-input"
                                    value={email}
                                    onChange={handleEmailChange}
                                  />
                                </Form.Group> */}
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="Describe your review"
                                      className="textt-box"
                                      value={review}
                                      onChange={handleReviewChange}
                                    />
                                  </Form.Group>
                                  <Form.Group controlId="formFile" className="mb-3">
                                    {/* <Form.Label>Upload Image</Form.Label> */}
                                    <div className="customBtnForFile">
                                      {/* Hidden file input */}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="fileInput"
                                      />
                                      {/* Button to trigger file input */}
                                      <label htmlFor="fileInput" className="buttonsUpload">
                                        <img src="/images/LandingPg/uploadI.png" className="uploadIcons" alt="Upload Icon" />
                                        Upload Image
                                      </label>
                                      {/* Display uploaded image */}
                                      {imageSrc && <img src={imageSrc} alt="Uploaded" />}
                                    </div>
                                    {/* <Form.Control  type="file" onChange={handleFileChange} /> */}
                                  </Form.Group>
                                  <Button variant="primary" type="submit" className="submit-button">
                                    Submit Review
                                  </Button>
                                </Form>
                              ) : (
                                <p className="noplaced">You are not placed this product</p>
                              )}

                            </div>
                          </Modal.Body>
                          {/* <Modal.Footer>
                      <div className=" submit-review">
                        <button
                          type="button"
                          className="btn submit-reviewbutton"
                        >
                          Submit Review
                        </button>
                      </div>
                    </Modal.Footer> */}
                        </Modal>
                      </div>
                      <p className="costumer-review">{getProduct?.totalReviews} Customer Review</p>

                      {/* <p className="Specifications">Specifications</p>
                    <div className="propertis">
                      {getattributes.slice(0, 6).map((element, index) => (
                        getattributes.length > 0 && (
                          <ul key={index} className="propertiesList">
                            <li >
                              <p className="propertyKey"> {element.key} :</p>
                            </li>
                            <li>
                              <p className="propertyKey"> {element.attribute}</p>
                            </li>
                          </ul>
                        )
                      ))}
                    </div> */}
                    </div>

                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="deliveryInfo">
                      <div className="deliveryTime">
                        <ul className="deliveryStatus">
                          <li>
                            <img src="/images/iconsImages/truck.jpg" className=" deliveryIcon" />
                          </li>
                          <li className="contentDelivey">
                            <p className="ExpText">Expected Delivery</p>
                            <p className="ExpText2">Order now, get in 3 hours. Log in to see exact delivery time slot.</p>
                          </li>
                        </ul>

                      </div>
                      <div className="listData">
                        <p className="itemName"><img className="Warrenty" src="/images/iconsImages/warrant.jpg" /></p>
                        <div>
                          <p className="itemName">1 Year Warranty</p>
                        </div>
                      </div>
                      <div className="listData">
                        <p className="itemName"><img className="Warrenty" src="/images/iconsImages/return.jpg" /></p>
                        <div>
                          <p className="itemName">Enjoy hassle free returns with this offer.</p>
                        </div>
                      </div>
                      <div className="listData">
                        <p className="itemName"><img className="Warrenty" src="/images/iconsImages/return.jpg" /></p>
                        <div>
                          <p className="itemName">FREE RETURNS</p>
                          <span className="spanItem">Get free returns on eligible items</span>
                        </div>
                      </div>
                      <div className="listData">
                        <p className="itemName"><img className="Warrenty" src="/images/iconsImages/shiping.jpg" /></p>
                        <div>
                          <p className="itemName">TRUSTED SHIPPING</p>
                          <span className="spanItem">Free shipping when you spend AED 100 and above on express items</span>
                        </div>
                      </div>
                      <div className="listData">
                        <p className="itemName"><img className="Warrenty" src="/images/iconsImages/contact.jpg" /></p>
                        <div>
                          <p className="itemName">CONTACTLESS DELIVERY</p>
                          <span className="spanItem">Your delivery will be left at your door, valid on prepaid orders only.</span>
                        </div>
                      </div>
                      {/* <div className="otherProducts">
                      <p className="otherTxt">{getProduct?.categoryName} - Other Products</p>
                      {getOtherProducts.map((value, index) => (
                        <div key={index} className="itemsList">

                          <ul className="itemsSwich" onClick={() => getProductInfo(value)}>
                            <li>
                              <img src={value?.thumbnail} className=" deliveryImg" />
                            </li>
                            <li>
                              <p className="prodctTxt">{value.title}</p>
                            </li>
                          </ul>


                        </div>
                      ))}
                    </div> */}
                      {/* <div>
                      <ul className="catImgs">
                        {getTwoCategoris.map((value, index) => (


                          <li key={index} className="itemsGroup">
                            <p className="cateTxts">{value?.firstDetail?.categoryName}</p>
                            <img onClick={() => handleItemClick(value._id)} src={value?.firstDetail?.thumbnail} className="caytogryImgs" />
                          </li>


                        ))}
                      </ul>
                    </div> */}
                    </div>

                  </div>
                </div>
              </div>


              <>
                <p className="overViewSection">Overview</p>
                <div className="row">


                  <div className="col-md-6">
                    <div className="technical-details">
                      <div className="technical-header">
                        <h1 className="head-technical">Highlights</h1>
                      </div>
                      <ul className="itemlist">
                        {Array.isArray(getDec) && getDec.map((element, index) => (
                          <li key={index} className="listitemsparas">
                            {element?.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {getProduct.description.length > 0 ? (
                      <div>
                        <h1 className="head-technical">Overview</h1>
                        <p className="listitemsparas">{getProduct.description}</p>
                      </div>
                    ) : (
                      null
                    )}


                  </div>
                  <div className="col-md-6">
                    <div className="technical-details-table">
                      <div className="technical-header">
                        <h1 className="head-technical">Specifications</h1>
                      </div>
                      <table className="table table-bordered">
                        <tbody>
                          {getattributes.map((element, index) => (
                            getattributes.length > 0 && (
                              <tr key={index} className="table-technical-row">
                                <td className="table-technical-data" scope="row">
                                  <p className="keyPoints"> {element.key}</p>
                                </td>
                                <td className="table-technical-mobile">{element.attribute}</td>
                              </tr>
                            )
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>




                </div>
                <div className="row">
                  {getPramotional.map((value, index) => (
                    <div key={index}>
                      <img width={'100%'} src={value.image} alt={getProduct.title} />
                    </div>
                  ))}
                </div>

                <SimilerProducts />

                <div className="bannerInnerPage">
                  {/* <div className="bannerInside">
                  <img className="bannerIn" src="/images/LandingPg/insideBanner.png" />
                </div> */}
                  <div className="pBrandings" >
                    <Pbrands />
                  </div>

                </div>

              </>

              <div
                className="modal fade"
                id="staticBackdropVideo"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <h5 className="modal-title" id="staticBackdropLabel">
            Modal title
          </h5> */}
                      <button
                        onClick={pauseVideo}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ReactPlayer
                      playing={false}
                      controls={true}
                      ref={playerRef}
                      width={'100%'}
                      url={getFinalVideoUrl} // Assuming getFinalVideoUrl is defined somewhere
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <>


        </>

      </div>

    </>
  );
};




export default ProductView;
